/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { registerLocale } from 'react-datepicker';

import { APIConfig } from 'src/config';

import { decimalSeparator } from './constants';

const DEFAULT_LOCALE = 'en-AU';
const SUPPORTED_DEFAULT_LOCALES = ['en', 'en-AU', 'it', 'ja'];

/**
 * Returns true if the locale is one supported natively by Powertracer.
 * @param {string} locale
 * @returns {boolean} true if supported, false if not.
 */
const isSupported = (locale) => (SUPPORTED_DEFAULT_LOCALES.indexOf(locale) >= 0);

/**
 * Returns the default locale for the selected locale.
 * @param {string} locale a valid locale as per
 * @returns {string} the stripped locale without any partner specifics.
 */
export const getDefaultLocale = (locale) => {
  if (!locale || typeof locale !== 'string') {
    return DEFAULT_LOCALE;
  }

  const matches = locale.match(/^(.+?)-x-(.+?)$/);
  const secondaryLocale = matches ? matches[1] : locale;

  if (isSupported(secondaryLocale)) {
    return secondaryLocale;
  }

  // const [tertiaryLocale] = secondaryLocale.split('-');
  // if (isSupported(tertiaryLocale)) {
  //   return tertiaryLocale;
  // }

  return DEFAULT_LOCALE;
};

/**
 * Returns the language for the locale.
 * @param {string} locale
 * @returns {string} the languange.
 */
export const getLanguage = (locale) => {
  const [language] = locale.split('-');

  return language;
};

/**
 * Returns the locale of the application based on the partner/retailer
 * @returns {string} - locale
 */
export const getLocale = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const requestLocale = urlParams.get('locale');
  const partnerLocale = APIConfig()?.LOCALE;

  return requestLocale || partnerLocale || DEFAULT_LOCALE;
};

/**
 * Retuns the language pack(static) that is used across the application
 * @returns {object} - language pack map
 */
export const i18nMessages = () => {
  const locale = getLocale();
  let messagesLocale;
  try {
    require.resolve(`../../../assets/i18n/compiled/${locale}/${locale}.json`);
    messagesLocale = require(`../../../assets/i18n/compiled/${locale}/${locale}.json`);
  } catch (e) {
    messagesLocale = {};
  }

  const defaultLocale = getDefaultLocale(locale);
  const messagesDefaultLocale = require(`../../../assets/i18n/compiled/${defaultLocale}/${defaultLocale}.json`);

  const dateRangeLocale = require(`date-fns/locale/${defaultLocale}/index.js`);
  registerLocale(defaultLocale, dateRangeLocale);

  // return messagesLocale;
  return { ...messagesDefaultLocale, ...messagesLocale };
};

/**
 * Decimal formatting - if there is a separator defined in the decimal separator it
 * will use that or will use the default (.)
 * @param {string} value
 * @returns {string} - decimal separated value
 */
export const i18nDecimalFormat = (value) => {
  const replaceByValue = decimalSeparator[getLocale()];
  if (!replaceByValue) {
    return value;
  }
  return (value.replace('.', replaceByValue));
};
