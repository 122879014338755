import { Duration, DateTime } from 'luxon';

/**
 * For a given date range a finish date is represented as midnight of the day in our date range
 * object, however as a time range it is considered at 24:00:00 that is midnight the following day.
 * A day is a 24 hours interval (not withstanding dayligh saving and/or leap seconds). Hence the
 * addition of one day.
 * @param {string} timezone
 * @param {object} dateRange
 * @param {DateTime} dateRange.start
 * @param {DateTime} dateRange.finish
 * @returns {object} - time range.
 */
const dateRangeToTimeRange = (timezone, dateRange) => {
  if (!timezone || !dateRange) return null;

  const { start: startDate, finish: finishDate } = dateRange;

  const start = DateTime.fromObject(
    { year: startDate.year, month: startDate.month, day: startDate.day },
    { zone: timezone },
  );
  const finish = DateTime.fromObject(
    { year: finishDate.year, month: finishDate.month, day: finishDate.day },
    { zone: timezone },
  ).plus(Duration.fromISO('P1D'));

  return { start, finish };
};

export default dateRangeToTimeRange;
