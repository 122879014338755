import { DateTime, Duration } from 'luxon';

import rangesFor from 'src/util/array';
import {
  DAY_PUBLIC_HOLIDAY, AllTimesOfDay, AllDaysOfWeek, AllMonthsOfYear,
  DayNames, MonthNames,
} from 'src/util/constants';
import { getDayNames, getMonthNames } from 'src/util/i18n/constants';
import { getLocale } from 'src/util/i18n/handler';

// Given a VolumeCharge or Clause, return an array of conditions
/**
 * Given a time object, return the time of use conditions.
 * @param {object} timeObject
 * @param {boolean} timeObject.ignoreDaylightSavings
 * @param {boolean} timeObject.ignorePublicHolidays
 * @param {string} timeObject.timezone
 * @param {object} intl
 * @returns {Array<string>} An array of conditions.
 */
// eslint-disable-next-line max-len
export const timeOfUseConditions = ({ ignoreDaylightSavings, ignorePublicHolidays, timezone }, intl) => {
  const conditions = [];
  // conditional push will be removed after the phase2 implementation of i18n
  if (ignoreDaylightSavings) {
    conditions.push(intl ? intl.formatMessage({ id: 'time_of_use.time_of_use_conditions.ignore_day_light_savings.label', defaultMessage: 'ignoring daylight savings time' }) : 'ignoring daylight savings time');
  }
  if (ignorePublicHolidays) {
    conditions.push(intl ? intl.formatMessage({ id: 'time_of_use.time_of_use_conditions.ignore_public_holidays.label', defaultMessage: 'public holidays treated as calendar days' }) : 'public holidays treated as calendar days');
  }
  if (timezone) {
    conditions.push(`${intl ? intl.formatMessage({ id: 'time_of_use.time_of_use_conditions.using_explicit_timezone.label', defaultMessage: 'using explicit timezone' }) : 'using explicit timezone'} '${timezone}'`);
  }
  return conditions;
};

// Given a VolumeCharge or Clause, return a text representation of the time of use
export const timeOfUseDetails = (object) => {
  let display = '';

  const { monthsOfYear, daysOfWeek, timesOfDay } = object;

  const monthsOfYearRanges = rangesFor(monthsOfYear, AllMonthsOfYear, true);
  const daysOfWeekRanges = rangesFor(daysOfWeek, AllDaysOfWeek.slice(0, 7), true);

  const fmtRange = (range, labels) => {
    const { start, finish } = range;

    const label = (key) => (labels[key] || '');

    if (start === finish) {
      return label(start);
    }
    return `${label(start)} to ${label(finish)}`;
  };

  if (AllMonthsOfYear.every((month) => monthsOfYear.includes(month))
    && AllDaysOfWeek.every((day) => daysOfWeek.includes(day))
    && JSON.stringify(timesOfDay) === JSON.stringify([AllTimesOfDay])) {
    display += 'At all times';
  } else {
    display = [
      ...monthsOfYearRanges.map((range) => fmtRange(range, MonthNames)),
      ...daysOfWeekRanges.map((range) => fmtRange(range, DayNames)),
    ].join(', ');

    if (daysOfWeek.includes(DAY_PUBLIC_HOLIDAY)) {
      display += `${daysOfWeek.length > 1 ? ' &' : ','} Public Holidays`;
    }

    const fmtNum = (number) => number.toString().padStart(2, '0');
    const times = timesOfDay.map(({ start, finish }) => (
      `${fmtNum(start.hours)}:${fmtNum(start.minutes)}-${fmtNum(finish.hours)}:${fmtNum(finish.minutes)}`
    ));
    display += `, between ${times.slice(0, times.length - 1).join(', ')}${times.length > 1 ? ' and ' : ''}${times[times.length - 1]}`;
  }

  return display;
};

/**
 * Given a VolumeCharge or Clause, return a text representation of the time of use
 * Once i18n is implemented in admin pages this function has to be renamed to timeOfUseDetails and
 * the duplicate/clone function can be removed
 * @param {object} intl
 * @param {object} clause
 * @returns {string} - text representation of time of use
 */
export const timeOfUseDetailsI18n = (intl, clause) => {
  if (!intl || !clause) {
    return null;
  }

  const { monthsOfYear, daysOfWeek, timesOfDay } = clause;

  const monthsOfYearRanges = rangesFor(monthsOfYear, AllMonthsOfYear, true);
  const daysOfWeekRanges = rangesFor(daysOfWeek, AllDaysOfWeek.slice(0, 7), true);

  const fmtRange = (range, labels) => {
    const { start, finish } = range;

    const label = (key) => (labels[key] || '');

    if (start === finish) {
      return label(start);
    }
    return intl.formatMessage({ id: 'time_of_use.time_of_use_details.time_range.details', defaultMessage: '{start} to {finish}' }, { start: label(start), finish: label(finish) });
  };
  const MonthNamesList = getMonthNames(intl.formatMessage);
  const DayNamesList = getDayNames(intl.formatMessage);
  if (AllMonthsOfYear.every((month) => monthsOfYear.includes(month))
    && AllDaysOfWeek.every((day) => daysOfWeek.includes(day))
    && JSON.stringify(timesOfDay) === JSON.stringify([AllTimesOfDay])) {
    return intl.formatMessage({ id: 'time_of_use.time_of_use_details.all_times.text', defaultMessage: 'At all times' });
  }

  const locale = getLocale();
  const monthRange = monthsOfYearRanges.map((range) => fmtRange(range, MonthNamesList));
  const dayRange = daysOfWeekRanges.map((range) => fmtRange(range, DayNamesList));
  let displayList = [];
  if (monthRange?.length > 0) {
    displayList = [...displayList, ...monthRange];
  }
  if (dayRange?.length > 0) {
    displayList = [...displayList, ...dayRange];
  }

  if (daysOfWeek.includes(DAY_PUBLIC_HOLIDAY)) {
    displayList.push(intl.formatMessage({ id: 'time_of_use.time_of_use_details.public_holidays.label', defaultMessage: 'Public holidays' }));
  }

  const timesofDayList = timesOfDay.map((timeObj) => {
    const { start, finish } = timeObj;
    const startDT = DateTime.local(
      2024,
      1,
      1,
      start.hours,
      start.minutes,
      start.seconds,
      { locale },
    );

    let startTime = startDT.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
    if (start.hours === 24) {
      startTime = startDT.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h24' });
    }

    // The year and date are used as a reference to create the datetime object
    // In the UI we just use the time (hour and minutes) and those are the only relevant values
    const finishDT = DateTime.local(
      2024,
      1,
      1,
      finish.hours,
      finish.minutes,
      finish.seconds,
      { locale },
    );
    let finishTime = finishDT.toLocaleString({
      hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
    });
    if (finish.hours === 24) {
      finishTime = finishDT.toLocaleString({
        hour: '2-digit', minute: '2-digit', hourCycle: 'h24',
      });
    }

    return intl.formatMessage({
      id: 'time_of_use.time_of_use_details.times_of_day.clock_timerange',
      defaultMessage: '{startTime}-{finishTime}',
    }, { startTime, finishTime });
  });

  if (timesofDayList.length === 1) {
    displayList.push(
      intl.formatMessage({
        id: 'time_of_use.time_of_use_details.times_of_day.list',
        defaultMessage: 'between {timesofDayList}',
      }, { timesofDayList: timesofDayList[0] }),
    );
  }
  if (timesofDayList.length > 1) {
    displayList.push(
      intl.formatMessage({
        id: 'time_of_use.time_of_use_details.times_of_day.list',
        defaultMessage: 'between {timesofDayList}',
      }, { timesofDayList: timesofDayList[0] }),
    );
    // remove first item (it is added in to the final list already - line above)
    timesofDayList.shift();
    displayList = [...displayList, ...timesofDayList];
  }

  const longFormat = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
  });
  return longFormat.format(displayList);
};

// Given an object with monthsOfYear, daysOfWeek, timesOfDay, ignorePublicHolidays
// checks to see if it always applies
export const timeOfUseIsSimple = (object) => {
  const {
    monthsOfYear, daysOfWeek, timesOfDay, ignorePublicHolidays,
  } = object;

  const appliesAllMonths = JSON.stringify(AllMonthsOfYear) === JSON.stringify(monthsOfYear);
  const appliesAllDays = JSON.stringify(AllDaysOfWeek) === JSON.stringify(daysOfWeek)
    || (
      ignorePublicHolidays
      && JSON.stringify(AllDaysOfWeek) === JSON.stringify(daysOfWeek.concat(DAY_PUBLIC_HOLIDAY))
    );
  const appliesAllHours = JSON.stringify(timesOfDay) === JSON.stringify([AllTimesOfDay]);

  return appliesAllMonths && appliesAllDays && appliesAllHours;
};

// Convert a time of day object to a Duration
export const timeOfDayToDuration = (timeOfDay) => Duration.fromObject({
  hours: timeOfDay.hours,
  minutes: timeOfDay.minutes,
  seconds: timeOfDay.seconds,
  milliseconds: timeOfDay.nanos && (timeOfDay.nanos / 1000000),
});
