import { DateTime, Duration } from 'luxon';
import Big from 'big.js';
import calculateCarbonData from 'src/lib/carbondata/calculator';
import {
  METER, TRADE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
} from 'src/util/constants';

/**
 * The time offset from the ISO8601 string.
 *
 * When we are dealing with day or monthly charts we are dealing with a date and not a timestamp.
 * As we are using the trailing edge of the the period in use, `24:00:00` is rendered as `00:00:00`
 * on the following day. Hence we need to offset that aggregation period before building the primary
 * data.
 * @param {string} aggregation is an ISO8601 duration string, or empty.
 * @returns {Duration} - time offset.
 */
export const getTimeOffset = (aggregation) => (aggregation.match(/^PT\d+[HMS]/) ? 0 : Duration.fromISO(aggregation));

/**
 * Builds the summary for the primary data from a combination of meter, trade, trade rule and
 * historian data.
 * @param {object} item - sell or buy object
 * @param {string} direction - sell or buy
 * @param {Array} meterNodes - array of meter nodes (meter data)
 * @param {string} aggregation - interval of the chart (day or half an hr interval)
 * @param {object} propertyRegion ISO 3166 country or country-region code. For example: "AU-VIC" (ref: <https://en.wikipedia.org/wiki/ISO_3166>.
 * @param {boolean} aggregated
 * @returns {Array} an array, representing a [key, value] pairing, wherein the value is the full
 *                  set of data for trade and meter information for presentation for that time
 *                  interval.
 */
export const buildDataSummary = (
  item,
  direction,
  meterNodes,
  aggregation,
  propertyRegion,
  aggregated,
) => {
  const timeOffset = getTimeOffset(aggregation);
  const { timestamp, value, flags } = item;
  const originalTimeStamp = aggregated ? timestamp : DateTime.fromSeconds(timestamp);
  const x = DateTime.fromISO(originalTimeStamp).minus(timeOffset);
  const getDate = (agg, start, finish) => DateTime.fromSeconds(agg.match(/^PT\d+[HMS]$/) ? finish : start);
  const finalTradeList = {};
  meterNodes?.forEach((nodes) => {
    nodes?.tradeSetSummary?.forEach((node) => {
      if (node?.key?.direction === direction) {
        const { data, key } = node;

        data?.forEach((tradeData) => {
          const formattedTradeData = { ...tradeData };
          const {
            directions, range, types, volume,
          } = formattedTradeData;
          const { start, finish } = range;
          const dateOfTrade = getDate(aggregation, start, finish);

          if (dateOfTrade.ts === x.ts) {
            const { ruleId } = key;
            const tradeCarbonDataProps = {
              dataType: TRADE,
              timestamp: x,
              energy: Big(volume),
              propertyRegion,
              direction: directions,
              types,
            };
            formattedTradeData.carbon = Number(calculateCarbonData(tradeCarbonDataProps));
            finalTradeList[ruleId] = formattedTradeData;
          }
        });
      }
    });
  });

  const meterCarbonDataProps = {
    dataType: METER, timestamp: x, energy: Big(value), propertyRegion, direction,
  };

  const meterCarbonData = calculateCarbonData(meterCarbonDataProps);

  return [
    x.toUTC(),
    {
      meter: {
        timestamp: x, value: Number(Big(value)), flags, carbon: meterCarbonData,
      },
      trades: finalTradeList,
    },
  ];
};

/**
 * Process data in each meter node and facilitate building the data summary.
 * @param {object} buy
 * @param {object} sell
 * @param {Array} meterNodes array of meter nodes (meter data).
 * @param {string} aggregation interval of the chart (day or half an hr interval).
 * @param {object} propertyRegion state and country info of the property.
 * @param {boolean} isAggregated - whether the chart view is aggregated or not
 * @returns {object} - formatted meter nodes.
 */
const processMeterNodes = (
  buy,
  sell,
  meterNodes,
  aggregation,
  propertyRegion,
  isAggregated,
) => {
  const buyExist = buy && buy.length > 0;
  const sellExist = sell && sell.length > 0;
  const dataConsumed = buyExist ? Object.fromEntries(buy.map((item) => buildDataSummary(
    item,
    TRADE_DIRECTION_BUY,
    meterNodes,
    aggregation,
    propertyRegion,
    isAggregated,
  ))) : {};

  const dataGenerated = sellExist ? Object.fromEntries(sell.map((item) => buildDataSummary(
    item,
    TRADE_DIRECTION_SELL,
    meterNodes,
    aggregation,
    propertyRegion,
    isAggregated,
  ))) : {};

  return { dataGenerated, dataConsumed };
};

/**
 * Builds the primary data source for the property.
 * @param {object} aggregatedMeterData meter data info aggregated by timestamp.
 * @param {string} aggregation interval of the chart (day or half an hr interval).
 * @param {Array} meterNodes array of meter nodes (meter data).
 * @param {object} propertyRegion state and country info of the property.
 * @param {boolean} isAggregated
 * @returns {object} - primary data for the property dashboard.
 */
export const getPrimaryData = (
  aggregatedMeterData,
  aggregation,
  meterNodes,
  propertyRegion,
  isAggregated,
) => {
  let finalResp = {};
  if (aggregatedMeterData && Object.keys(aggregatedMeterData).length > 0) {
    if (isAggregated) {
      const { buy, sell } = aggregatedMeterData;
      const { dataConsumed, dataGenerated } = processMeterNodes(
        buy,
        sell,
        meterNodes,
        aggregation,
        propertyRegion,
        isAggregated,
      );
      finalResp = { buy: dataConsumed, sell: dataGenerated };
    } else {
      Object.keys(aggregatedMeterData).forEach((nodeId) => {
        const {
          buy, sell, title, identifier,
        } = aggregatedMeterData[nodeId];
        const meterNode = meterNodes.filter((node) => node.id === nodeId);
        const { dataConsumed, dataGenerated } = processMeterNodes(
          buy,
          sell,
          meterNode,
          aggregation,
          propertyRegion,
          isAggregated,
        );

        finalResp[nodeId] = {
          buy: dataConsumed, sell: dataGenerated, title, identifier,
        };
      });
    }
  }

  return finalResp;
};
