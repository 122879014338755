import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage, injectIntl } from 'react-intl';

import Loading from 'src/components/Loading';
import convertEnergyPrice from 'src/util/conversions';
import { timeOfUseConditions, timeOfUseDetailsI18n, timeOfUseIsSimple } from 'src/util/timeOfUse';
import { i18nDecimalFormat } from 'src/util/i18n/handler';

class TradeRuleListTableItemClause extends React.Component {
  /**
   * Time of use conditions wrapped in a div.
   * @param {object} intl - International message formatting.
   * @param {object} volumeCharge - The volume charge.
   * @returns {React.ReactElement | null} The returned time of use conditions wrap in a div or null.
   */
  static timeOfUseConditions(intl, volumeCharge) {
    const conditions = timeOfUseConditions(volumeCharge, intl);
    if (conditions.length === 0) {
      return null;
    }

    return (<div>{`(${intl.formatList(conditions, { type: 'unit' })})`}</div>);
  }

  /**
   * Base clause information.
   * @param {object} intl
   * @param {object} clause
   * @returns {React.ReactElement | null} The bas clause wrapped in a div.
   */
  static baseClause(intl, clause) {
    const formattedClausePrice = (
      <strong>
        {i18nDecimalFormat(convertEnergyPrice(clause.price).toFixed(3))}
      </strong>
    );
    const clausePriceTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_list.trade_rule_list_table_item_clause.clause_price.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const clausePriceUnitLabel = <FormattedMessage id="trade_rule.trade_rule_list.trade_rule_list_table_item_clause.clause_price.abbr.label" defaultMessage="c/kWh" />;
    const clausePriceUnit = <abbr title={clausePriceTitle}>{clausePriceUnitLabel}</abbr>;
    return (
      <div key={clause.id}>
        <FormattedMessage
          id="trade_rule.trade_rule_list.trade_rule_list_table_item_clause.base_clause.text"
          defaultMessage="{formattedClausePrice} {clausePriceUnit}"
          values={{
            formattedClausePrice, clausePriceUnit,
          }}
        />
      </div>
    );
  }

  static renderSimple(intl, clause) {
    return TradeRuleListTableItemClause.baseClause(intl, clause);
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { clause, intl, showConditions } = this.props;

    if (timeOfUseIsSimple(clause)) {
      return TradeRuleListTableItemClause.renderSimple(intl, clause);
    }

    return (
      <li key={clause.id}>
        <FormattedMessage
          id="trade_rule.trade_rule_list.trade_rule_list_table_item_clause.clause.text"
          defaultMessage="{baseClause} {clauseTimeOfUseDetails}{showClauseConditions}"
          values={{
            baseClause: TradeRuleListTableItemClause.baseClause(intl, clause),
            clauseTimeOfUseDetails: timeOfUseDetailsI18n(intl, clause),
            showClauseConditions: showConditions
              && TradeRuleListTableItemClause.timeOfUseConditions(intl, clause),
          }}
        />
      </li>
    );
  }
}

TradeRuleListTableItemClause.propTypes = {
  clause: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  showConditions: PropTypes.bool,
};

TradeRuleListTableItemClause.defaultProps = {
  showConditions: true,
};

export default injectIntl(createFragmentContainer(
  TradeRuleListTableItemClause,
  {
    clause: graphql`
      fragment TradeRuleListTableItemClause_clause on Clause {
        id
        price
        timezone
        ignorePublicHolidays
        ignoreDaylightSavings
        publicHolidayRegion
        monthsOfYear
        daysOfWeek
        timesOfDay {
          start {
            hours
            minutes
            seconds
          }
          finish {
            hours
            minutes
            seconds
          }
        }
      }
    `,
  },
));
