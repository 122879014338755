import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { DateTime, Duration } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useRefetchableFragment } from 'react-relay';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import { carbonLabel } from 'src/enosikit/components/Chart/components/ChartHelpers';
import Heading from 'src/enosikit/components/Heading';
import Map from 'src/enosikit/components/Map';
import FlashesStore from 'src/stores/FlashesStore';
import BrowserProtocol from 'src/util/history';
import {
  DATA_METER_AGGREGATE, INHIBIT_CARBON_DATA_VIEWS, SOURCE, SOURCE_HISTORIAN, SOURCE_TRADES, UNIT,
  UNIT_CURRENCY, UNIT_CARBON, UNIT_ENERGY, VIEW_SELECTOR,
} from 'src/util/constants';
import { getDefaultLocale } from 'src/util/i18n/handler';

import PropertyShowControl from './PropertyShowControl';
import { showTradeRuleMsg, WARNING_TIMEOUT } from './PropertyShowHelpers';
import PropertyShowTradeEnergy from './PropertyShowTradeEnergy';
import dateRangeToTimeRange from './time';

const PropertyShowFragment = graphql`
fragment PropertyShow on Query
@refetchable(queryName: "PropertyShowRefetchQuery")
@argumentDefinitions(
  id: {type: "ID!"},
  start: { type: "Timestamp!" },
  finish: { type: "Timestamp!" },
  timeZone: { type: "String!" }
  historianAggregation: { type: "String!" }
  tradeAggregation: { type: "String!"  }
) {
  property(id:$id) {
  id
  externalIdentifier
  active { start finish }
  title
  timezone
  publicHolidayRegion
  address {
    line1
    line2
    city
    state
    country
    postcode
    gps {
      latitude
      longitude
    }
  }
  propertyUsers(first: 500) {
    edges {
      node {
        id
        externalIdentifier
        active { start finish }
        user {
          id
          externalIdentifier
          email
          givenName
          familyName
          active { start finish }
        }
      }
    }
  }
  meters(first:500) {
    edges {
      node {
        id
        identifier
        externalIdentifier
        active { start finish }
        title
        tradePointId
        property {
          title
        }
        primaryBillingPoint {
          identifier
        }
        rules(first: 999999999) {
          edges {
            node {
              id
              tradeType
              buyer {
                userId
                user {
                  id
                  email
                  givenName
                  familyName
                }
                tradePoint {
                  id
                  type
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              seller {
                userId
                user {
                  id
                  email
                  givenName
                  familyName
                }
                tradePoint {
                  id
                  type
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              state
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              proposedAt
              proposedBy {
                id
                email
                givenName
                familyName
              }
              acceptedAt
              acceptedBy {
                id
                email
                givenName
                familyName
              }
            }
          }
        }
        dataConsumed: calculatedData(start: $start, finish: $finish, aggregation: $historianAggregation, timeZone: $timeZone, metric: "elec_energy_consumed") {
          aggregation
          metric {
            identifier
          }
          timeZone
          timeRange {
            start
            finish
          }
          data {
            timestamp
            value
            flags {
              identifier
              description
            }
          }
        }
        dataGenerated: calculatedData(start: $start, finish: $finish, aggregation: $historianAggregation, timeZone: $timeZone, metric: "elec_energy_generated") {
          aggregation
          metric {
            identifier
          }
          timeZone
          timeRange {
            start
            finish
          }
          data {
            timestamp
            value
            flags {
              identifier
              description
            }
          }
        }
        tradeSetSummary(start: $start, finish: $finish, timeZone: $timeZone, aggregation: $tradeAggregation, groups: [SUMMARY_GROUP_TRADE_POINT, SUMMARY_GROUP_TRADE_RULE, SUMMARY_GROUP_DIRECTION, SUMMARY_GROUP_TRADE_TYPE]) {
          key {
            tradePointId
            ruleId
            direction
            type
          }
          data {
            range { start finish }
            buyerTradePointIds
            sellerTradePointIds
            directions
            types
            value
            volume
            averagePrice
            tradeCount
            intervalCount
          }
        }
      }
    }
  }
 }
}
`;

/**
 * Provides the input for the chart display (carbon/currency/wh) type selector
 * @param {string} unit
 * @param {string} source
 * @returns {Array<object>} - chart display control options.
 */
const controlOptionFunc = (unit, source) => {
  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
  const unitOps = {
    group: UNIT,
    items: [{
      value: UNIT_ENERGY, label: 'Wh', active: (unit === UNIT_ENERGY), disabled: false,
    }, {
      value: UNIT_CURRENCY, label: <FormattedMessage id="property.property_show.type_selector.currency.label" defaultMessage="$" />, active: (unit === UNIT_CURRENCY), disabled: (source === SOURCE_HISTORIAN),
    }],
  };
  if (inhibitCarbonDataViews) {
    return [unitOps];
  }

  const carbonController = {
    value: UNIT_CARBON, label: carbonLabel(), active: (unit === UNIT_CARBON),
  };
  unitOps.items.push(carbonController);
  return [unitOps];
};

/**
 * @param {object} intl - i18n
 * @returns {React.ReactComponentElement} - warning dialog fragment
 */
const warningDialog = (intl) => {
  const msgWarning = intl.formatMessage({
    id: 'property.property_show.property_show_helpers.message_warning',
    defaultMessage: 'Hi! Are you trying to view your trade rules? Create and manage them by selecting Trade Rules in the menu above.',
  });
  return (
    <>
      <FontAwesomeIcon className="me-1" size="lg" icon={faTriangleExclamation} />
      {msgWarning}
    </>
  );
};
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - PropertyShow component
 */
function PropertyShow(props) {
  if (!props) {
    return <Loading />;
  }
  const [data, refetch] = useRefetchableFragment(PropertyShowFragment, props);
  const { property } = data || {};
  const { __fragmentOwner } = props || {};
  const { variables } = __fragmentOwner || {};
  const { start, finish, timeZone } = variables || {};

  let s = DateTime.local().startOf('day').minus(Duration.fromISO('P1M1D'));
  if (start && start !== undefined) {
    const ts = DateTime.fromSeconds(start);
    if (ts.invalidExplanation === null) {
      s = ts;
    }
  }

  let f = s.plus(Duration.fromISO('P1M'));
  if (finish && finish !== undefined) {
    const ts = DateTime.fromSeconds(finish);
    if (ts.invalidExplanation === null) {
      f = ts.minus(Duration.fromISO('P1D'));
    }
  }

  const duration = f.diff(s);

  let agg = 'PT30M';
  if (duration.as('days') > 7) {
    agg = 'P1D';
  }

  const [historianAggregation, setHistorianAggregation] = useState(agg);
  const [tradeAggregation, setTradeAggregation] = useState(agg);
  const [timespan, setTimespan] = useState({
    start: s,
    finish: f,
  });

  const [unit, setUnit] = useState(UNIT_ENERGY);
  const [source, setSource] = useState(SOURCE_TRADES);
  const [tradeBtnClicks, setTradeBtnClicks] = useState(0);
  const [viewSelectorOpen, setViewSelectorOpen] = useState(false);
  const [downloadSelectorOpen, setDownloadSelectorOpen] = useState(false);
  const [chartView, setChartView] = useState(DATA_METER_AGGREGATE);
  const firstTimeRender = useRef(true);

  const doRefetch = () => {
    let tz = APIConfig().DEFAULT_TIMEZONE;
    if (timeZone && timeZone !== undefined) {
      tz = timeZone;
    }

    const { start: startTime, finish: finishTime } = dateRangeToTimeRange(tz, timespan);
    const timespanDuration = finishTime.diff(startTime);

    let aggregate = 'PT30M';
    if (timespanDuration.as('days') > 7) {
      aggregate = 'P1D';
    }

    refetch(
      {
        id: property.id,
        start: startTime.toSeconds(),
        finish: finishTime.toSeconds(),
        timeZone: tz,
        historianAggregation: aggregate,
        tradeAggregation: aggregate,
      },

      {
        onComplete: (error) => {
          if (error) {
            FlashesStore.flash(FlashesStore.ERROR, error);
          } else {
            const { match } = props;
            const { location } = match;
            const { pathname } = location;
            setHistorianAggregation(aggregate);
            setTradeAggregation(aggregate);

            BrowserProtocol.navigate({
              action: 'REPLACE',
              pathname,
              search: `?start=${startTime.toISODate()}&finish=${finishTime.toISODate()}`,
              hash: '',
              state: { start: startTime.toISODate(), finish: finishTime.toISODate() },
            });
          }
        },

      },
    );
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [timespan]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  const toggle = (dropdownType) => {
    if (dropdownType === VIEW_SELECTOR) {
      setViewSelectorOpen(!viewSelectorOpen);
    } else {
      setDownloadSelectorOpen(!downloadSelectorOpen);
    }
  };

  const controlSetStateFunc = (opts, intl) => {
    const { group, value } = opts;

    let chartSource = source;
    let chartUnit = unit;
    let btnClicks = tradeBtnClicks;
    const warningMessage = warningDialog(intl);

    FlashesStore.reset();
    if (value === chartSource && chartSource === SOURCE_TRADES) {
      if (btnClicks === 0) {
        clearTimeout();
        btnClicks += 1;
      } else {
        showTradeRuleMsg(warningMessage);
      }
      setTimeout(() => {
        setTradeBtnClicks(0);
      }, WARNING_TIMEOUT);
    }
    if (group === UNIT) {
      chartUnit = value;
    }
    if (group === SOURCE) {
      chartSource = value;
      if (chartUnit === UNIT_CURRENCY) {
        chartUnit = UNIT_ENERGY;
      }
    }
    setSource(chartSource);
    setUnit(chartUnit);
    setTradeBtnClicks(btnClicks);
  };

  const handleTimespanUpdate = (updatedStart, updatedFinish) => {
    setTimespan({ start: updatedStart, finish: updatedFinish });
  };

  const renderPropertyControls = (intl) => {
    const { start: startTime, finish: finishTime } = timespan;
    const locale = getDefaultLocale();
    const updatedStartTime = startTime.setLocale(locale);
    const updatedFinishTime = finishTime.setLocale(locale);

    return (
      <PropertyShowControl
        property={property}
        timespan={{ start: updatedStartTime, finish: updatedFinishTime }}
        timespanUpdateFunc={handleTimespanUpdate}
        controlOptionFunc={() => controlOptionFunc(unit, source)}
        controlSetStateFunc={(opts) => controlSetStateFunc(opts, intl)}
        toggle={toggle}
        viewSelectorOpen={viewSelectorOpen}
        downloadSelectorOpen={downloadSelectorOpen}
        setChartView={setChartView}
        chartView={chartView}
        aggregation={historianAggregation}
      />
    );
  };

  const { address, title } = property;
  const addr = new Address(address);
  const intl = useIntl();

  const pageTitle = intl.formatMessage({ id: 'property.property_show.page.page.title', defaultMessage: 'Enosi - Property: {propertyTitle}' }, { propertyTitle: title });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs breadcrumbs={[{
        name: <FormattedMessage id="property.property_show.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
      }, { name: title }]}
      />
      <Heading
        alias={title}
        address={addr.simpleString()}
        context={<FormattedMessage id="property.property_show.heading.overview.title" defaultMessage="Overview" />}
        controls={renderPropertyControls(intl)}
      />
      <PropertyShowTradeEnergy
        property={property}
        historianAggregation={historianAggregation}
        tradeAggregation={tradeAggregation}
        timespan={timespan}
        handleTimespanUpdate={handleTimespanUpdate}
        unit={unit}
        source={source}
        controlSetStateFunc={(opts) => controlSetStateFunc(opts, intl)}
        chartView={chartView}
      />
      {address && <Map address={address} />}
    </>
  );
}

export default PropertyShow;

PropertyShow.propTypes = {
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }),
};

PropertyShow.defaultProps = {
  relay: undefined,
};
