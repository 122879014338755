import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isFloat from 'validator/es/lib/isFloat';
import { FormattedMessage } from 'react-intl';

import convertEnergyPrice from 'src/util/conversions';

class InputPrice extends React.Component {
  constructor(props) {
    super(props);

    const { defaultValue } = this.props;

    this.state = {
      value: defaultValue !== null ? (format('.3~f')(convertEnergyPrice(defaultValue))) : '',
    };
  }

  handleChange = (event) => {
    const { handlePriceChange } = this.props;
    const { value } = event.target;

    this.setState({ value });
    handlePriceChange(isFloat(value) ? (parseFloat(value) / 100000) : null);
  };

  render() {
    const {
      idPrefix, label, helpText, valid,
    } = this.props;
    const { value } = this.state;

    return (
      <FormGroup>
        <Label for={`${idPrefix}Price`}>{label}</Label>
        <Input
          type="number"
          name="price"
          id={`${idPrefix}Price`}
          value={value}
          onChange={this.handleChange}
          step="any"
          valid={valid !== null && valid}
          invalid={valid !== null && !valid}
        />
        <FormFeedback>
          <FormattedMessage id="time_of_use.input_price.form_feedback.invalid" defaultMessage="Invalid price" />
        </FormFeedback>
        <FormText>{helpText}</FormText>
      </FormGroup>
    );
  }
}

InputPrice.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  defaultValue: PropTypes.number,
  valid: PropTypes.bool,
  handlePriceChange: PropTypes.func.isRequired,
};

InputPrice.defaultProps = {
  label: 'Price',
  helpText: '',
  defaultValue: null,
  valid: null,
};

export default InputPrice;
