import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTradePointInlineLabel } from 'src/helpers/tradeHelpers';
import { TRADE_RULE_STATE_ACCEPTED } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import TradeRuleTimelineStep from './TradeRuleTimelineStep';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepAccepted component
 */
function TradeRuleTimelineStepAccepted(props) {
  const {
    metersMap, ruleId, tradeType, clauses, buyer, seller, timestamp, user,
    isCurrentStep, hasNextStep, propertyTimeZone,
  } = props;

  const intl = useIntl();

  let description;
  let direction;
  if (buyer.tradePoint.id in metersMap) {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.buy.details"
        defaultMessage="To buy for {buyerTradepoint} from {sellerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
    direction = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.trade_direction_buy.label', defaultMessage: 'Buying' });
  } else if (seller.tradePoint.id in metersMap) {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.trade_direction_sell.details"
        defaultMessage="To sell from {sellerTradepoint} to {buyerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
    direction = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.trade_direction_sell.label', defaultMessage: 'Selling' });
  } else {
    description = (
      <FormattedMessage
        id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.trade_direction_unspecified.details"
        defaultMessage="To buy for {buyerTradepoint} from {sellerTradepoint}"
        values={{
          buyerTradepoint: getTradePointInlineLabel(buyer.tradePoint, intl),
          sellerTradepoint: getTradePointInlineLabel(seller.tradePoint, intl),
        }}
      />
    );
    direction = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.trade_direction_unspecified.label', defaultMessage: 'Trade direction unspecified' });
  }

  const priceRange = tradeRulePriceRange({ clauses });
  const formattedPrice = i18nDecimalFormat(convertEnergyPrice(priceRange.minimum).toFixed(3));

  return (
    <TradeRuleTimelineStep
      propertyTimeZone={propertyTimeZone}
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_ACCEPTED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faCheckCircle} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    >
      <li className="mb-3">
        {description}
      </li>
      <li className="mb-3">
        {priceRange.maximum === priceRange.minimum && (
          <FormattedMessage
            id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.price.min_only.details"
            defaultMessage="{tradeDirection} at a price of {price} {currencyPerEnergyUnit}"
            values={{
              tradeDirection: <strong>{direction}</strong>,
              // TO DO: Implement d3-format locale support (PT-1124)
              price: <strong>{formattedPrice}</strong>,
              currencyPerEnergyUnit: <abbr title={intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
            }}
          />
        )}
        {priceRange.maximum !== priceRange.minimum && (
          <FormattedMessage
            id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.price.min_and_max.details"
            defaultMessage="{tradeDirection} at a price of {minimum} to {maximum} {currencyPerEnergyUnit}"
            values={{
              tradeDirection: <strong>{direction}</strong>,
              // TO DO: Implement d3-format locale support (PT-1124)
              maximum: <strong>{convertEnergyPrice(priceRange.maximum).toFixed(3)}</strong>,
              minimum: <strong>{convertEnergyPrice(priceRange.minimum).toFixed(3)}</strong>,
              currencyPerEnergyUnit: <abbr title={intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_timeline.trade_rule_timeline_step_accepted.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
            }}
          />
        )}
        {' '}

      </li>
    </TradeRuleTimelineStep>
  );
}

TradeRuleTimelineStepAccepted.propTypes = {
  metersMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  buyer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  seller: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  clauses: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({
      node: (PropTypes.shape({
        price: PropTypes.number.isRequired,
      })),
    })),
  }).isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  propertyTimeZone: PropTypes.string.isRequired,
};

TradeRuleTimelineStepAccepted.defaultProps = {
};

export default TradeRuleTimelineStepAccepted;
