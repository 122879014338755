import Big from 'big.js';
import { EMISSION_FACTORS, UNIT_CARBON_EMISSIONS_ACRONYM } from 'src/lib/carbondata/constants';
import { emissionFactorForRegion, emissionFactors } from 'src/lib/carbondata/helpers';
import { APIConfig } from 'src/config';
import { INHIBIT_CARBON_DATA_VIEWS } from 'src/util/constants';
import { getFileName, getTradeRules } from './propertyDataHelper';
import { getMeterNodes } from './propertyDataManager';
import dateRangeToTimeRange from '../time';

/**
 * Builds the meter data for the json (data pack) download.
 * @param {Array<object>} meterNodes
 * @returns {Array<object>} - meter schema.
 */

export const buildMeterSchema = (node) => {
  if (!node) {
    return null;
  }
  const { dataConsumed, dataGenerated } = node || {};

  const {
    aggregation: consumedAgg, timeRange: consumedRange, timeZone: consumeZone,
    metric: consumedMetric, data: consumedData,
  } = dataConsumed || {};
  const {
    aggregation: generatedAgg, timeRange: generatedRange, timeZone: generatedZone,
    metric: generatedMetric, data: generatedData,
  } = dataGenerated || {};
  const { identifier: consumedIdentifier } = consumedMetric || {};
  const { identifier: generatedIdentifier } = generatedMetric || {};

  const meterSchema = {};

  if (consumedIdentifier) {
    meterSchema[consumedIdentifier] = {
      metric: consumedMetric,
      aggregation: consumedAgg,
      timeRange: consumedRange,
      timeZone: consumeZone,
      data: consumedData,
    };
  }

  if (generatedIdentifier) {
    meterSchema[generatedIdentifier] = {
      metric: generatedMetric,
      aggregation: generatedAgg,
      timeRange: generatedRange,
      timeZone: generatedZone,
      data: generatedData,
    };
  }

  return meterSchema;
};
/**
 * Description
 * @param {Array<object>} trades
 * @param {string} interval - trade interval
 * @returns {Array<object>} - trade schema
 */
export const buildTradeSchema = (trades, interval = 'P1D') => {
  if (!trades || trades?.length === 0) {
    return null;
  }
  const tradeSchema = [];
  trades.forEach((trade) => {
    const { data, key } = trade;
    const { ruleId, type } = key;
    data.forEach((datum) => {
      const {
        averagePrice, buyerTradePointIds, directions, range: timeRanga,
        sellerTradePointIds, value: tradeValue, volume: tradeVolume,
      } = datum;
      const buyerTradePointId = buyerTradePointIds && buyerTradePointIds[0];
      const sellerTradePointId = sellerTradePointIds && sellerTradePointIds[0];
      const direction = directions && directions[0];
      const price = { units: averagePrice };
      const volume = { units: tradeVolume };
      const value = { units: tradeValue };
      const tradeObj = {
        buyerTradePointId,
        direction,
        interval,
        price,
        ruleId,
        sellerTradePointId,
        timeRanga,
        type,
        value,
        volume,
      };
      tradeSchema.push(tradeObj);
    });
  });
  return tradeSchema;
};

/**
 * Builds the user data for the json (data pack) download.
 * @param {object} propertyUsers
 * @returns {object} - user schema.
 */
export const buildUserSchema = (propertyUsers) => {
  if (!propertyUsers) {
    return null;
  }
  const userNodes = propertyUsers.edges?.map((item) => item.node.user);
  const users = {};
  userNodes?.forEach((user) => {
    const { id } = user;
    users[id] = user;
  });
  return users;
};

/**
 * Builds the trade and meter data schema for the json (data pack) download.
 * @param {object} property
 * @param {object} timeRange - {start, finish}
 * @param {string} aggregation - e.g 'P1D'
 * @param {object} intl - i18n react-intl
 * @returns {object} - json data schema
 */
export const buildDataSchema = (property, timeRange, aggregation, intl) => {
  const { title: propertyTitle, meters: meterList } = property;
  const { start, finish } = timeRange;
  const fileName = getFileName(propertyTitle, { start, finish }, '', intl);

  const meterNodes = getMeterNodes(meterList);

  if (meterNodes.length === 0) {
    return null;
  }

  // Generate the trade rule map.
  const allRules = getTradeRules(meterNodes);
  const tradeRuleMap = {};
  allRules.forEach((rule) => {
    const { id } = rule;
    tradeRuleMap[id] = rule;
  });

  const tradeData = {};
  const meterData = {};

  // Generate the list of meters.
  const meters = [];
  meterNodes.forEach((node) => {
    const {
      active, externalIdentifier, identifier, title, tradeSetSummary: trades, tradePointId,
    } = node || {};

    meters.push({
      active,
      externalIdentifier,
      identifier,
      title,
      tradePointId,
    });

    // Adding meter and trade data
    meterData[identifier] = buildMeterSchema(node);
    tradeData[tradePointId] = buildTradeSchema(trades, aggregation);
  });

  return {
    fileName, meterData, meters, tradeData, tradeRuleMap,
  };
};

/**
 * Calculate units for the JSON data download.
 * @param {Big} carbonEmissionality
 * @returns {number} - units.
 */
const toFixedDecimalUnits = (carbonEmissionality) => Big(
  carbonEmissionality,
).minus(Big(carbonEmissionality).mod(1)).toNumber();

/**
 * Calculate nanos for the JSON data download.
 * @param {Big} carbonEmissionality
 * @returns {number} - nanos.
 */
export const toFixedDecimalNanos = (carbonEmissionality) => Big(
  carbonEmissionality,
).mod(1).times(10 ** 9).toFixed(0);

/**
 * Calculate the value object for the JSON data download.
 * @param {Big} big - carbon emissionality
 * @returns {object} - {units, nanos}.
 */
const toFixedDecimal = (big) => ({
  units: toFixedDecimalUnits(big), nanos: toFixedDecimalNanos(big),
});

/**
 * Builds the carbon emissions data for the json (data pack) download.
 * @param {object} property
 * @param {object} dateRange - {start, finish}
 * @returns {object} - carbonEmissionalityData [{'AU-WA': [{start, finish, value, units}]}]
 */
export const buildCarbonEmissions = (property, dateRange) => {
  if (!property) {
    return null;
  }

  const { timezone, publicHolidayRegion } = property;
  if (!timezone || !publicHolidayRegion) {
    return null;
  }

  const timerange = dateRangeToTimeRange(timezone, dateRange);
  const { start: startTime, finish: finishTime } = timerange;

  const validEmissionFactors = emissionFactors(
    EMISSION_FACTORS,
    publicHolidayRegion,
    startTime,
    finishTime,
  );

  const carbonData = {};

  validEmissionFactors.forEach((validEmissionFactor) => {
    const { timeRange: factorTimeRange } = validEmissionFactor;
    const { start: factorStart, finish: factorFinish } = factorTimeRange;
    const carbonEmissionalityForRegion = emissionFactorForRegion(
      validEmissionFactor,
      publicHolidayRegion,
    );
    const { region, value: carbonEmmissionalityValue } = carbonEmissionalityForRegion;
    if (!carbonData[region]) {
      carbonData[region] = [];
    }

    carbonData[region].push({
      start: factorStart,
      finish: factorFinish,
      value: toFixedDecimal(carbonEmmissionalityValue),
      units: UNIT_CARBON_EMISSIONS_ACRONYM,
    });
  });

  return carbonData;
};

/**
 * Prepares the schema for json(data pack) download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {object} property - property data
 * @param {object} dateRange - {start, finish}
 * @param {string} aggregation - e.g "P1D"
 * @param {object} intl - i18n react-intl
 * @returns {object} - json data schema and file name
 */
export const processDataPack = (property, dateRange, aggregation, intl) => {
  if (!property) {
    return null;
  }

  const {
    address, externalIdentifier, title, propertyUsers,
  } = property;

  const users = buildUserSchema(propertyUsers);
  const {
    fileName, tradeData, tradeRuleMap, meterData, meters,
  } = buildDataSchema(property, dateRange, aggregation, intl);

  let data = {
    address,
    externalIdentifier,
    title,
    users,
    meters,
    meterData,
    tradeData,
    tradeRuleMap,
  };

  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
  if (inhibitCarbonDataViews) {
    return { data, fileName };
  }

  const carbonEmissionalityData = buildCarbonEmissions(property, dateRange);
  data = { ...data, ...{ carbonEmissionalityData } };

  return { data, fileName };
};
