import { Link } from 'found';
import React, { Element, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Badge, Button, ButtonGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter,
  DropdownMenu, DropdownItem, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import Time from 'src/enosikit/components/Time';
import AcceptNominatedTradeMutation from 'src/mutations/AcceptNominatedTradeMutation';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import RejectNominatedTradeMutation from 'src/mutations/RejectNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_RULE_CANCEL, TRADE_RULE_REJECT, TRADE_RULE_ACCEPT,
  TRADE_RULE_STATE_PROPOSED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import username from 'src/util/decorators/username';
import isActive from 'src/util/isActive';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import { tradeRulePriceRange } from 'src/util/tradeRule';

/**
 * Error notification when a trade proposal action fails.
 * @param {string} error
 */
export const handleNominatedTradeMutationFailure = (error) => {
  FlashesStore.flash(FlashesStore.ERROR, error);
};

/**
 *  Success notification when a trade proposal is cancelled.
 * @param {object} response
 */
export const handleCancelNominatedTradeMutationSuccess = (response) => {
  const { id } = response.cancelNominatedTrade;
  const successMessage = <FormattedMessage id="trade_rule.trade_rule_customer_proposals.handle_cancel_nominated_trade_mutation_success.message" defaultMessage="Cancelled peer-to-peer trade rule proposal with id: {id}." values={{ id }} />;
  FlashesStore.flash(
    FlashesStore.SUCCESS,
    successMessage,
  );
};

/**
 * Success notification when a trade proposal is rejected.
 * @param {object} response
 */
export const handleRejectNominatedTradeMutationSuccess = (response) => {
  const { id } = response.rejectNominatedTrade;
  const successMessage = <FormattedMessage id="trade_rule.trade_rule_customer_proposals.handle_rejected_nominated_trade_mutation_success" defaultMessage="Rejected peer-to-peer trade rule proposal with id: {id}." values={{ id }} />;

  FlashesStore.flash(
    FlashesStore.SUCCESS,
    successMessage,
  );
};

/**
 * Success notification when a trade proposal is accepted.
 * @param {object} response
 */
export const handleAcceptNominatedTradeMutationSuccess = (response) => {
  const { id } = response.acceptNominatedTrade;
  const successMessage = <FormattedMessage id="trade_rule.trade_rule_customer_proposals.handle_accepted_nominated_trade_mutation_success" defaultMessage="Accepted peer-to-peer trade rule proposal with id: {id}." values={{ id }} />;

  FlashesStore.flash(
    FlashesStore.SUCCESS,
    successMessage,
  );
};

/**
 * Segregates trade proposals in to buy and sell with more context.
 * @param {string} id - logged user
 * @param {object} proposals
 * @returns {object} - trade rules (segregated).
 */
export const getTradeRules = (id, proposals) => {
  const rules = {
    buy: [], sell: [], counterPartySell: [], counterPartyBuy: [], count: 0,
  };

  const displayList = [TRADE_RULE_ACCEPT, TRADE_RULE_REJECT, TRADE_RULE_CANCEL];
  if (!id || !proposals || !proposals.edges.length === 0) { return rules; }

  proposals.edges.forEach((ruleEdge) => {
    const { node: ruleNode } = ruleEdge;
    if (ruleNode.state !== TRADE_RULE_STATE_PROPOSED) return;

    const actions = [];
    if (ruleNode.buyer.user.id === id) {
      if (ruleNode.buyer.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }
    if (ruleNode.seller.user.id === id) {
      if (ruleNode.seller.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }
    actions.sort((a, b) => displayList.indexOf(a) - displayList.indexOf(b));
    // If the seller is the proposer...

    if (ruleNode.seller.user.id === ruleNode.proposedBy.id) {
      if (id === ruleNode.seller.user.id && ruleNode.seller.tradePoint.id) {
        rules.sell.push({ ...ruleNode, actions });
      } else {
        rules.counterPartySell.push({ ...ruleNode, actions });
      }
    } else if (ruleNode.buyer.user.id === ruleNode.proposedBy.id) {
      if (id === ruleNode.buyer.user.id && ruleNode.buyer.tradePoint.id) {
        rules.buy.push({ ...ruleNode, actions });
      } else {
        rules.counterPartyBuy.push({ ...ruleNode, actions });
      }
    }
  });

  return rules;
};

/**
 * Trade rule action handler
 * @param {object} rule
 * @param {string} action
 * @param {object} opts
 */
export const tradeRuleAction = (rule, action, opts = {}) => {
  const { id } = rule;
  const errorMessage = <FormattedMessage id="trade_rule.trade_rule_customer_proposals.trade_rule_action.default.message" defaultMessage="Action {action} is not possible" values={{ action }} />;

  switch (action) {
    case TRADE_RULE_CANCEL:
      CancelNominatedTradeMutation(
        id,
        handleCancelNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    case TRADE_RULE_REJECT:
      RejectNominatedTradeMutation(
        id,
        handleRejectNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    case TRADE_RULE_ACCEPT:
      AcceptNominatedTradeMutation(
        id,
        opts.tradePointId,
        handleAcceptNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    default:
      FlashesStore.flash(
        FlashesStore.ERROR,
        errorMessage,
      );
  }
};

/**
 * Prepares the list of available action buttons for a trade proposal.
 * @param {object} rule
 * @param {object} viewer
 * @returns {React.ReactElement} - Action buttons for a trade proposal.
 */
export const tradeRuleActionButton = (rule, viewer) => {
  if (!rule || !viewer) {
    return null;
  }
  const {
    id, state, buyer, seller,
  } = rule;

  const counterPartyTradePointId = [buyer.tradePoint.id, seller.tradePoint.id].filter(Boolean)[0];

  if (state !== TRADE_RULE_STATE_PROPOSED) {
    return null;
  }

  const { meters: meterConn } = viewer;
  const meterNodes = (!meterConn || !meterConn.edges)
    ? [] : meterConn.edges.map((edge) => (edge.node));

  const options = meterNodes.reduce((acc, el) => {
    const {
      identifier, title, tradePointId, active,
    } = el;
    if (tradePointId === counterPartyTradePointId) { return acc; }
    if (!el.property || !el.property.id) { return acc; }
    acc[el.property.id] = acc[el.property.id] || { property: el.property, tradePoints: [] };
    acc[el.property.id].tradePoints.push({
      identifier, title, tradePointId, active,
    });
    return acc;
  }, {});

  const buttons = [];

  rule.actions.forEach((action) => {
    if (action === TRADE_RULE_ACCEPT) {
      buttons.push(
        <UncontrolledButtonDropdown key={`${id}-${action}`}>
          <DropdownToggle caret>
            <FormattedMessage id="trade_rule.trade_rule_customer_proposals.trade_rule_action_button.accept.label" defaultMessage="Accept" />
          </DropdownToggle>
          <DropdownMenu>
            {Object.values(options).map((opts) => {
              const { property, tradePoints } = opts;
              return (
                <Fragment key={property.id}>
                  <DropdownItem header key={`${id}-${action}-${property.id}`}>
                    {property.title}
                  </DropdownItem>
                  {tradePoints.map((tp) => (
                    <DropdownItem
                      onClick={() => (
                        tradeRuleAction(rule, action, { tradePointId: tp.tradePointId })
                      )}
                      disabled={!isActive(tp.active)}
                      key={`${id}-${action}-${tp.tradePointId}`}
                    >
                      {`${tp.title} (${tp.identifier})`}
                    </DropdownItem>
                  ))}
                </Fragment>
              );
            })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>,
      );
      return;
    }
    const title = action.toLowerCase().split('_').splice(-1)[0];
    buttons.push(
      <Button onClick={() => tradeRuleAction(rule, action)} key={`${id}-${action}`}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </Button>,
    );
  });

  return (
    <ButtonGroup>
      {buttons}
    </ButtonGroup>
  );
};

/**
 * Prepares the list of buy proposal cards.
 * @param {object} intl - i18n react-intl
 * @param {Array<object>} tradeRules
 * @param {object} viewer
 * @returns {Array<Element>} - List of buy proposal cards.
 */
export const tradeRulesBuy = (intl, tradeRules, viewer) => {
  if (!tradeRules) {
    return null;
  }

  return tradeRules.map((rule) => {
    const priceRange = tradeRulePriceRange(rule);

    const pricePerUnitTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_proposals.trade_rules_buy.buy_price.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const pricePerUnitLabel = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_proposals.trade_rules_buy.buy_price.energy_cost.abbr.label', defaultMessage: 'c/kWh' });
    const energyPricePerUnit = <abbr title={pricePerUnitTitle}>{pricePerUnitLabel}</abbr>;

    return (
      <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
        <CardBody>
          <CardTitle tag="h4">{rule.id}</CardTitle>
          <CardSubtitle tag="h5" className="mb-2">
            <FormattedMessage id="trade_rule.trade_rule_customer_proposals.trade_rules_buy.proposed.sub_title.text" defaultMessage="Proposed" />
          </CardSubtitle>
          <CardText>
            <FormattedMessage
              id="trade_rule.trade_rule_customer_proposals.trade_rules_buy.has_proposed_to.text"
              defaultMessage="{buyerName} has proposed to buy from {sellerName}"
              values={{
                buyerName: <strong>{username(rule.buyer.user)}</strong>,
                sellerName: <strong>{username(rule.seller.user)}</strong>,
              }}
            />
            <br />
            <FormattedMessage
              id="trade_rule.trade_rule_customer_proposals.trade_rules_buy.proposed_by.text"
              defaultMessage="Proposed by {userName} on {timestamp}"
              values={{
                userName: <strong>{username(rule.proposedBy)}</strong>,
                timestamp: <Time
                  child={getStringAndNumericFormattedDate(intl, rule.proposedAt)}
                  unixTimestamp={rule.proposedAt}
                />,
              }}
            />
            <br />
            {priceRange.maximum !== priceRange.minimum && (
              <FormattedMessage
                id="trade_rule.trade_rule_customer_proposals.trade_rules_buy.buy_price.range"
                defaultMessage="Buy price: {minPrice} to {maxPrice} {energyPricePerUnit}"
                values={{
                  // TO DO: Implement d3-format locale support (PT-1124)
                  minPrice: <strong>{convertEnergyPrice(priceRange.minimum).toFixed(3)}</strong>,
                  maxPrice: <strong>{convertEnergyPrice(priceRange.maximum).toFixed(3)}</strong>,
                  energyPricePerUnit,
                }}
              />
            )}
            {priceRange.maximum === priceRange.minimum && (
              <FormattedMessage
                id="trade_rule.trade_rule_customer_proposals.trade_rules_buy.buy_price.flat"
                defaultMessage="Buy price: {price} {energyPricePerUnit}"
                values={{
                  // TO DO: Implement d3-format locale support (PT-1124)
                  price: <strong>{convertEnergyPrice(priceRange.minimum).toFixed(3)}</strong>,
                  energyPricePerUnit,
                }}
              />
            )}
            <br />
            <Link
              to={`/trade-rules/${rule.id}`}
            >
              <Badge color="mid">{rule.id}</Badge>
            </Link>
          </CardText>
        </CardBody>
        <CardFooter>{tradeRuleActionButton(rule, viewer)}</CardFooter>
      </Card>
    );
  });
};

/**
 * Prepares the list of sell proposal cards.
 * @param {object} intl - i18n react-intl
 * @param {Array<object>} tradeRules
 * @param {object} viewer
 * @returns {Array<Element>} - List of buy proposal cards.
 */
export const tradeRulesSell = (intl, tradeRules, viewer) => {
  if (!tradeRules) {
    return null;
  }
  return tradeRules.map((rule) => {
    const priceRange = tradeRulePriceRange(rule);

    const pricePerUnitTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_proposals.trade_rules_sell.sell_price.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const pricePerUnitLabel = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_proposals.trade_rules_sell.sell_price.energy_cost.abbr.label', defaultMessage: 'c/kWh' });
    const energyPricePerUnit = <abbr title={pricePerUnitTitle}>{pricePerUnitLabel}</abbr>;

    return (
      <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
        <CardBody>
          <CardTitle tag="h4">{rule.id}</CardTitle>
          <CardSubtitle tag="h5" className="mb-2">
            <FormattedMessage id="trade_rule.trade_rule_customer_proposals.trade_rules_sell.proposed.sub_title.text" defaultMessage="Proposed" />
          </CardSubtitle>
          <CardText>
            <FormattedMessage
              id="trade_rule.trade_rule_customer_proposals.trade_rules_sell.has_proposed_to.text"
              defaultMessage="{sellerName} has proposed to sell to {buyerName}"
              values={{
                sellerName: <strong>{username(rule.seller.user)}</strong>,
                buyerName: <strong>{username(rule.buyer.user)}</strong>,
              }}
            />
            <br />
            <FormattedMessage
              id="trade_rule.trade_rule_customer_proposals.trade_rules_sell.proposed_by.text"
              defaultMessage="Proposed by {userName} on {timestamp}"
              values={{
                userName: <strong>{username(rule.proposedBy)}</strong>,
                timestamp: <Time
                  child={getStringAndNumericFormattedDate(intl, rule.proposedAt)}
                  unixTimestamp={rule.proposedAt}
                />,
              }}
            />
            <br />
            {priceRange.maximum !== priceRange.minimum && (
              <FormattedMessage
                id="trade_rule.trade_rule_customer_proposals.trade_rules_sell.sell_price.range"
                defaultMessage="Sell price: {minPrice} to {maxPrice} {energyPricePerUnit}"
                values={{
                  // TO DO: Implement d3-format locale support (PT-1124)
                  minPrice: <strong>{convertEnergyPrice(priceRange.minimum).toFixed(3)}</strong>,
                  maxPrice: <strong>{convertEnergyPrice(priceRange.maximum).toFixed(3)}</strong>,
                  energyPricePerUnit,
                }}
              />
            )}
            {priceRange.maximum === priceRange.minimum && (
              <FormattedMessage
                id="trade_rule.trade_rule_customer_proposals.trade_rules_sell.sell_price.flat"
                defaultMessage="Sell price: {price} {energyPricePerUnit}"
                values={{
                  // TO DO: Implement d3-format locale support (PT-1124)
                  price: <strong>{convertEnergyPrice(priceRange.minimum).toFixed(3)}</strong>,
                  energyPricePerUnit,
                }}
              />
            )}
            <br />
            <Link
              to={`/trade-rules/${rule.id}`}
            >
              <Badge color="mid">{rule.id}</Badge>
            </Link>
          </CardText>
        </CardBody>
        <CardFooter>{tradeRuleActionButton(rule, viewer)}</CardFooter>
      </Card>
    );
  });
};
