import Big from 'big.js';
import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, Row, Col, Card, CardBody, CardFooter, CardHeader, Form, FormFeedback, FormGroup, FormText,
  Input, Label,
} from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Loading from 'src/components/Loading';
import ProposeCommunityTradeMutation from 'src/mutations/ProposeCommunityTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import { AllMonthsOfYear, AllDaysOfWeek, AllTimesOfDay } from 'src/util/constants';

class TradeRuleSetCommunityFlatForm extends React.Component {
  static isValidPrice(price) {
    return price !== '' && parseFloat(price) >= 0;
  }

  constructor(props) {
    super(props);

    const { meter } = this.props;
    const { tradePointId, communityRules } = meter;

    let buyPrice = '';
    const currentBuyRules = communityRules.edges
      .filter((edge) => edge.node.buyer.tradePoint.id === tradePointId);
    if (currentBuyRules.length === 1) {
      const currentBuyClauses = currentBuyRules[0].node.clauses.edges;
      if (currentBuyClauses.length === 1) {
        buyPrice = (Big(currentBuyClauses[0].node.price).times(100000)).toString();
      }
    }

    let sellPrice = '';
    const currentSellRules = communityRules.edges
      .filter((edge) => edge.node.seller.tradePoint.id === tradePointId);
    if (currentSellRules.length === 1) {
      const currentSellClauses = currentSellRules[0].node.clauses.edges;
      if (currentSellClauses.length === 1) {
        sellPrice = (Big(currentSellClauses[0].node.price).times(100000)).toString();
      }
    }

    this.state = {
      buyPrice,
      sellPrice,
      buyPriceValid: null,
      sellPriceValid: null,
      processing: false,
    };
  }

  handleBuyPriceChange = (event) => {
    const buyPrice = event.target.value;
    const buyPriceValid = TradeRuleSetCommunityFlatForm.isValidPrice(buyPrice);

    this.setState({ buyPrice, buyPriceValid });
  };

  handleSellPriceChange = (event) => {
    const sellPrice = event.target.value;
    const sellPriceValid = TradeRuleSetCommunityFlatForm.isValidPrice(sellPrice);

    this.setState({ sellPrice, sellPriceValid });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { property, meter } = this.props;
    const { timezone, publicHolidayRegion } = property;
    const { tradePointId } = meter;
    const { buyPrice, sellPrice, processing } = this.state;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.state.invalid.still_processing" defaultMessage="We are still processing your request..." />);
      return;
    }

    if (!this.validate()) {
      FlashesStore.flash(FlashesStore.ERROR, <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.state.invalid_data" defaultMessage="Form data not valid. Please see below." />);
      return;
    }

    this.setState({ processing: true });
    FlashesStore.reset();

    const input = {
      tradePointId,
      buyClauses: [
        {
          price: parseFloat(buyPrice) / 100000,
          timezone,
          publicHolidayRegion,
          ignoreDaylightSavings: false,
          ignorePublicHolidays: false,
          monthsOfYear: AllMonthsOfYear,
          daysOfWeek: AllDaysOfWeek,
          timesOfDay: [AllTimesOfDay],
        },
      ],
      sellClauses: [
        {
          price: parseFloat(sellPrice) / 100000,
          timezone,
          publicHolidayRegion,
          ignoreDaylightSavings: false,
          ignorePublicHolidays: false,
          monthsOfYear: AllMonthsOfYear,
          daysOfWeek: AllDaysOfWeek,
          timesOfDay: [AllTimesOfDay],
        },
      ],
    };

    ProposeCommunityTradeMutation(
      input,
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  handleSubmitSuccess = (_response) => {
    const { property, router } = this.props;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.SUCCESS, <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.response_message.success" defaultMessage="Community trade rules have been set" />);

    router.push(`/properties/${property.id}/trade-rules/active`);
  };

  handleSubmitFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  validate = () => {
    const { buyPrice, sellPrice } = this.state;

    const buyPriceValid = TradeRuleSetCommunityFlatForm.isValidPrice(buyPrice);
    const sellPriceValid = TradeRuleSetCommunityFlatForm.isValidPrice(sellPrice);

    this.setState({ buyPriceValid, sellPriceValid });

    return buyPriceValid && sellPriceValid;
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      intl, property, meter, router,
    } = this.props;
    const {
      buyPrice, sellPrice, buyPriceValid, sellPriceValid, processing,
    } = this.state;

    const setCommunityTradeHeading = intl.formatMessage({ id: 'trade_rule.trade_rule_set_community_flat_form.heading.label', defaultMessage: 'Set community trade rules for {meter}' }, { meter: meter.title });
    const energyPriceUnitAbbrTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_set_community_flat_form.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const energyPriceUnitAbbrLabel = intl.formatMessage({ id: 'trade_rule.trade_rule_set_community_flat_form.energy_cost.abbr.label', defaultMessage: 'c/kWh' });
    const energyPriceUnit = (
      <abbr
        title={energyPriceUnitAbbrTitle}
      >
        {energyPriceUnitAbbrLabel}
      </abbr>
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              {setCommunityTradeHeading}
            </h2>
            <Link to={`/properties/${property.id}/meters/${meter.id}/trade-rules/community/set/time-of-use`} className="btn btn-darken ms-auto">
              <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.time_of_use_pricing.label" defaultMessage="Time-of-use pricing" />
            </Link>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="sellPrice"><FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.minimum_sell_price.label" defaultMessage="Minimum sell price" /></Label>
                  <Input type="number" name="sellPrice" id="sellPrice" value={sellPrice} onChange={this.handleSellPriceChange} step="any" disabled={processing} valid={sellPriceValid} invalid={sellPriceValid !== null && !sellPriceValid} />
                  <FormFeedback><FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.minimum.sell_price.form_feedback.invalid_price" defaultMessage="Invalid price" /></FormFeedback>
                  <FormText>
                    <FormattedMessage
                      id="trade_rule.trade_rule_set_community_flat_form.minimum_sell_price.hint"
                      defaultMessage="What is the minimum price, in {energyPriceUnit},
                    that you will sell your energy to the community?"
                      values={{ energyPriceUnit }}
                    />
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="buyPrice"><FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.maximum_buy_price.label" defaultMessage="Maximum buy price" /></Label>
                  <Input type="number" name="buyPrice" id="buyPrice" value={buyPrice} onChange={this.handleBuyPriceChange} step="any" disabled={processing} valid={buyPriceValid} invalid={buyPriceValid !== null && !buyPriceValid} />
                  <FormFeedback><FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.maximum_buy_price.form_feedback.invalid" defaultMessage="Invalid price" /></FormFeedback>
                  <FormText>
                    <FormattedMessage
                      id="trade_rule.trade_rule_set_community_flat_form.maximum_sell_price_hint"
                      defaultMessage=" What is the maximum price, in {energyPriceUnit},
                      that you will buy your energy from the community?"
                      values={{ energyPriceUnit }}
                    />
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" className="me-2" disabled={processing}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.submit.label" defaultMessage="Set" />
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
              <FormattedMessage id="trade_rule.trade_rule_set_community_flat_form.cancel.label" defaultMessage=" Cancel" />
            </Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

TradeRuleSetCommunityFlatForm.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRuleSetCommunityFlatForm.defaultProps = {
  property: null,
  meter: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleSetCommunityFlatForm,
  {
    property: graphql`
      fragment TradeRuleSetCommunityFlatForm_property on Property {
        id
        timezone
        publicHolidayRegion
      }
    `,
    meter: graphql`
      fragment TradeRuleSetCommunityFlatForm_meter on Meter {
        id
        identifier
        title
        tradePointId
        communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {
          edges {
            node {
              id
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
