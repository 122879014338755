import { DateTime } from 'luxon';
import Big from 'big.js';

import { APIConfig } from 'src/config';
import calculateCarbonData from 'src/lib/carbondata/calculator';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, EMISSION_FACTORS, UNIT_CARBON_EMISSIONS_ACRONYM,
  UNIT_ENERGY_ACRONYM,
} from 'src/lib/carbondata/constants';
import { emissionFactors, emissionFactorForRegion } from 'src/lib/carbondata/helpers';
import {
  BUY, CSV_DATA_TIMESTAMP_FORMAT, DIRECTIONS, METER, TRADE,
  INHIBIT_CARBON_DATA_VIEWS,
} from 'src/util/constants';

import { getTimeOffset } from './primaryDataBuilder';
import { getFileName, getTimeRange, getUserName } from './propertyDataHelper';
import { getMeterNodes } from './propertyDataManager';

const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);

/**
 * Prepares the trade parties (buyer and seller) info for a given trade rule id.
 * @param {string} ruleId - trade rule id.
 * @param {Array<object>} rules - trade rules.
 * @returns {object} - trade parties data
 */
export const getTradePartiesData = (ruleId, rules) => {
  if (!ruleId || !rules) {
    return null;
  }
  let finalData = null;
  rules.forEach((rule) => {
    const { id } = rule?.node || {};
    if (id && ruleId === id) {
      const { buyer, seller } = rule.node;
      const { tradePoint: buyerTradePoint, user: userBuyer } = buyer || {};
      const { meter: buyerMeter } = buyerTradePoint || {};
      const { tradePoint: sellerTradePoint, user: userSeller } = seller || {};
      const { meter: sellerMeter } = sellerTradePoint || {};
      finalData = {
        buyer: { buyerMeter, buyerName: getUserName(userBuyer) },
        seller: { sellerMeter, sellerName: getUserName(userSeller) },
      };
    }
  });
  return finalData;
};

/**
 * Prepares the meter data for download
 * @param {object} node - meter node
 * @param {string} fileName - download file name
 * @param {string} propertyRegion - code to identify the property region (for example 'AU-NSW')
 * @param {object} intl - i18n react-intl
 * @returns {object} - meter data
 */
export const getMeterDataForDownload = (node, fileName, propertyRegion, intl) => {
  const finalData = [];
  let name = fileName;
  const {
    dataConsumed, dataGenerated, identifier, title,
    primaryBillingPoint: billingPoint, property,
  } = node;
  const { identifier: billingPointIdentifier } = billingPoint;
  const { title: propertyTitle } = property;
  DIRECTIONS.forEach((dir) => {
    const dataSource = dir === BUY ? dataConsumed : dataGenerated;

    if (!dataSource) return;

    const {
      data, metric, aggregation, timeRange,
    } = dataSource;

    if (!name) {
      const { start, finish } = timeRange;
      name = getFileName(
        propertyTitle,
        { start: DateTime.fromSeconds(start), finish: DateTime.fromSeconds(finish) },
        METER.toLowerCase(),
        intl,
      );
    }
    data?.forEach((datum) => {
      const { timestamp, flags, value } = datum;
      const { start, finish } = getTimeRange(timestamp, aggregation);
      const timeOffset = getTimeOffset(aggregation);
      const originalTimeStamp = DateTime.fromSeconds(timestamp);
      const atTime = originalTimeStamp.minus(timeOffset);

      let dataObj = {
        propertyTitle,
        billingPointIdentifier,
        title,
        identifier,
        metric: metric.identifier,
        start,
        finish,
        value,
        units: UNIT_ENERGY_ACRONYM,
        flags: flags[0].identifier,
      };
      if (inhibitCarbonDataViews) {
        finalData.push(dataObj);
        return;
      }

      const validEmissionFactors = emissionFactors(
        EMISSION_FACTORS,
        propertyRegion,
        atTime,
        atTime,
      );
      const [emissionFactor] = validEmissionFactors;
      const carbonEmissionality = emissionFactorForRegion(emissionFactor, propertyRegion);
      const { value: carbonEmissionalityValue } = carbonEmissionality;
      const meterCarbonDataProps = {
        dataType: METER,
        timestamp: atTime,
        energy: Big(value),
        propertyRegion,
        direction: dir === BUY ? TRADE_DIRECTION_BUY : TRADE_DIRECTION_SELL,
      };
      const carbonEmissions = Big(
        calculateCarbonData(meterCarbonDataProps),
      ).times(1000); // carbon emissions are in g-CO2•e

      dataObj = {
        ...dataObj,
        carbonEmissionality: carbonEmissionalityValue,
        carbonEmissions,
        carbonEmissionsUnits: UNIT_CARBON_EMISSIONS_ACRONYM,
      };

      finalData.push(dataObj);
    });
  });
  return { data: finalData, name };
};

/**
 * Prepares the trade data for download
 * @param {object} node - meter node
 * @param {string} fileName - download file name
 * @param {string} propertyRegion - code to identify the property region (for example 'AU-NSW')
 * @param {object} dateRange - {start, finish}
 * @param {object} intl - i18n react-intl
 * @returns {object} - trade data
 */
export const getTradeDataForDownload = (node, fileName, propertyRegion, dateRange, intl) => {
  const {
    dataConsumed, dataGenerated, property, rules, tradeSetSummary: trades,
  } = node;
  const finalData = [];
  const { title: propertyTitle } = property;
  let name = fileName;
  const { start: startDateRange, finish: finishDateRange } = dateRange;

  if (!name) {
    name = getFileName(
      propertyTitle,
      { start: startDateRange, finish: finishDateRange },
      TRADE.toLowerCase(),
      intl,
    );
  }
  if (!trades) {
    return ({
      data: [],
      name: getFileName(
        propertyTitle,
        { start: startDateRange, finish: finishDateRange },
        TRADE.toLowerCase(),
        intl,
      ),
    });
  }
  trades.forEach((trade) => {
    const { data, key } = trade;
    const { ruleId, type, direction } = key;
    const dataSource = direction === TRADE_DIRECTION_BUY ? dataConsumed : dataGenerated;
    if (!dataSource) return;
    const { aggregation } = dataSource;
    const { buyer, seller } = getTradePartiesData(ruleId, rules?.edges);
    const { buyerMeter, buyerName } = buyer || {};
    const { sellerMeter, sellerName } = seller || {};
    const {
      identifier: buyerIdentifier, title: buyerTitle,
      property: buyerProperty,
    } = buyerMeter || {};

    const {
      identifier: sellerIdentifier, title: sellerTitle,
      property: sellerProperty,
    } = sellerMeter || {};

    data.forEach((datum) => {
      const {
        averagePrice: price, value, volume, range,
      } = datum;
      const { start, finish } = range;
      const timeOffset = getTimeOffset(aggregation);
      const originalTimeStamp = DateTime.fromSeconds(finish);
      const atTime = originalTimeStamp.minus(timeOffset);

      let dataObj = {
        ruleId,
        type,
        buyerName,
        buyerPropertyTitle: buyerProperty?.title || null,
        buyerTitle: buyerTitle || null,
        buyerIdentifier: buyerIdentifier || null,
        sellerName,
        sellerPropertyTitle: sellerProperty?.title || null,
        sellerTitle: sellerTitle || null,
        sellerIdentifier: sellerIdentifier || null,
        start: DateTime.fromSeconds(start).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
        finish: DateTime.fromSeconds(finish).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
        price,
        volume,
        value,
      };
      if (inhibitCarbonDataViews) {
        finalData.push(dataObj);
        return;
      }

      const validEmissionFactors = emissionFactors(
        EMISSION_FACTORS,
        propertyRegion,
        atTime,
        atTime,
      );
      const [emissionFactor] = validEmissionFactors;
      const carbonEmissionality = emissionFactorForRegion(emissionFactor, propertyRegion);
      const { value: carbonEmissionalityValue } = carbonEmissionality;
      const tradeCarbonDataProps = {
        dataType: TRADE,
        timestamp: atTime,
        energy: Big(volume),
        propertyRegion,
        direction,
        types: [type],
      };
      const carbonEmissions = Big(
        calculateCarbonData(tradeCarbonDataProps),
      ).times(1000); // carbon emissions are in g-CO2•e

      dataObj = {
        ...dataObj,
        carbonEmissionality: carbonEmissionalityValue,
        carbonEmissions,
        carbonEmissionsUnits: UNIT_CARBON_EMISSIONS_ACRONYM,
      };

      finalData.push(dataObj);
    });
  });
  return { data: finalData, name };
};

/**
 * Prepares the data object that gets feed in to the csv for download
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * @param {string} dataType - meter or trade
 * @param {object} rawData - meterdata of the property
 * @param {string} propertyRegion - code to identify the property region (for example 'AU-NSW')
 * @param {object} dateRange - {start, finish}
 * @param {object} intl - i18n react-intl
 * @returns {object} - data for the csv and file names
 */
export const processCSVData = (dataType, rawData, propertyRegion, dateRange, intl) => {
  if (!rawData || !dataType) {
    return null;
  }
  let fileName = '';
  let finalData = [];
  const meterNodes = getMeterNodes(rawData);

  meterNodes.forEach((node) => {
    if (dataType === METER) {
      const { data, name } = getMeterDataForDownload(node, fileName, propertyRegion, intl);
      finalData = [...finalData, ...data];
      fileName = name;
    } else {
      const { data, name } = getTradeDataForDownload(
        node,
        fileName,
        propertyRegion,
        dateRange,
        intl,
      );
      finalData = [...finalData, ...data];
      fileName = name;
    }
  });
  return { data: finalData, fileName };
};

/**
 * Prepares CSV data for download
 * @param {Array<object>} data - raw meter or trade data
 * @param {Array<string>} headers - csv file headers
 * @returns {string} - csv data
 */
export const prepareCSVData = (data, headers) => {
  const csvRows = [];
  csvRows.push(headers.join(','));

  data.forEach((item) => {
    const values = Object.values(item).join(',');
    csvRows.push(values);
  });

  return csvRows.join('\n');
};
