import { DateTime } from 'luxon';
import Big from 'big.js';
import { DIRECTIONS } from 'src/util/constants';
import {
  getCounterParty, getDataKey, getDataObject, getDirection, getTraderProperty, getTradeType,
} from './propertyDataHelper';

/**
 * Build and returns trade data per meter
 * @param {object} sourceData - chart primary data
 * @param {Array} rules - trade rules
 * @param {string} aggregation - interval of the chart (day or half an hr interval)
 * @param {?string} meterId
 * @returns {object} - trade data.
 */
export const buildDataByMeter = (sourceData, rules, aggregation, meterId) => {
  const resp = {
    buy: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
    },
    sell: {
      contracted: {},
      nominated: {},
      community: {},
      residual: {},
      summary: { value: 0, volume: 0, carbon: 0 },
    },
  };
  const { title = '', identifier = '' } = sourceData || {};
  DIRECTIONS.forEach((dir) => {
    if (!sourceData[dir]) {
      return;
    }
    // let currDate = '';

    Object.keys(sourceData[dir]).forEach((date) => {
      const { trades } = sourceData[dir][date];
      if (!trades) {
        return;
      }

      Object.keys(trades).forEach((ruleId) => {
        const {
          types, carbon = 0, value = 0, volume = 0,
        } = trades[ruleId];
        const type = types[0];

        const tradeType = getTradeType(type);
        const counterParty = getCounterParty(rules, ruleId, type, getDirection(dir));
        const counterPartyProperty = getTraderProperty(counterParty);
        const t = resp[dir][tradeType];
        const key = getDataKey(type, counterParty, counterPartyProperty, null, ruleId);
        const dataKey = meterId ? `${key}_${meterId}` : key;
        if (!(dataKey in t)) {
          t[dataKey] = getDataObject(type, counterParty, dataKey);
        }

        // Add counterparty property data if available
        t[dataKey].property = counterPartyProperty;

        // Add meter details
        t[dataKey].meter = { title, identifier };

        const dataSeries = t[dataKey].data;
        dataSeries[date] ||= {
          interval: { timestamp: DateTime.fromISO(date), length: aggregation },
          value: 0,
          volume: 0,
          carbon: 0,
        };

        const aggregateCarbonByDate = Big(dataSeries[date].carbon).plus(carbon);
        const aggregateValueByDate = Big(dataSeries[date].value).plus(value);
        const aggregateVolumeByDate = Big(dataSeries[date].volume).plus(volume);

        dataSeries[date].carbon = Number(aggregateCarbonByDate);
        dataSeries[date].value = Number(aggregateValueByDate);
        dataSeries[date].volume = Number(aggregateVolumeByDate);

        const aggregateCarbonSummary = Big(resp[dir].summary.carbon).plus(carbon);
        const aggregateValueSummary = Big(resp[dir].summary.value).plus(value);
        const aggregateVolumeSummary = Big(resp[dir].summary.volume).plus(volume);

        resp[dir].summary.carbon = Number(aggregateCarbonSummary);
        resp[dir].summary.value = Number(aggregateValueSummary);
        resp[dir].summary.volume = Number(aggregateVolumeSummary);
      });
    });
  });
  return resp;
};

/**
 * Build the trade data for the charts
 * @param {object} sourceData - chart primary data
 * @param {Array} rules -  trade rules
 * @param {string} aggregation  - interval of the chart (day or half an hr interval)
 * @param {boolean} isAggregated
 * @returns {object} finalResp - trade data for the chart
 */
export const buildTradeData = (sourceData, rules, aggregation, isAggregated) => {
  if (isAggregated) {
    return buildDataByMeter(sourceData, rules, aggregation);
  }
  const finalResp = {};
  Object.keys(sourceData).forEach((meterId) => {
    const resp = buildDataByMeter(sourceData[meterId], rules, aggregation, meterId);
    finalResp[meterId] = resp;
  });
  return finalResp;
};
