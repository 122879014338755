import Big from 'big.js';
import { DateTime } from 'luxon';
import {
  DEFAULT_CARBON_FREE_EMISSION_FACTOR, EMISSION_FACTORS,
  TRADE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_TYPE_RESIDUAL,
} from './constants';

/**
 * Determine if the combination is a residual buy trade.
 * @param {string} direction
 * @param {Array<string>} types
 * @returns {boolean} - is the trade a residual buy.
 */
export const isResidualBuy = (direction, types) => {
  const isDirectionBuy = direction.length > 0 && direction.includes(TRADE_DIRECTION_BUY);
  const isResidual = types.length > 0 && types.includes(TRADE_TYPE_RESIDUAL);
  return isDirectionBuy && isResidual;
};

/**
 * Calculate the total carbon emissions from a dataset.
 * @param {[key: any, data: object]} dataConsumed
 * @returns {Big} - total carbon emission.
 */
export const getTotalCarbonEmission = (dataConsumed) => {
  let totalCarbonEmission = Big(0);
  Object.keys(dataConsumed).forEach((item) => {
    const { trades } = dataConsumed[item];
    Object.keys(trades).forEach((trade) => {
      totalCarbonEmission = totalCarbonEmission.add(Big(trades[trade].carbon));
    });
  });

  return totalCarbonEmission;
};

/**
 * Determine if the dataset should use zero carbon emissions.
 * @param {string} dataType
 * @param {string} direction
 * @param {Array<string>} types
 * @returns {boolean} - zero emission or not.
 */
export const useDefaultCarbonFreeEmissions = (dataType, direction, types) => {
  if (dataType === TRADE) {
    return !isResidualBuy(direction, types);
  }
  // this is meter data. So calculate carbon emission only for the imports
  return direction === TRADE_DIRECTION_SELL;
};

/**
 * Find the emissions factor region, given the property region and the carbon data,
 * and return the dataset for that region. If not found, returns `undefined`.
 * @param {object} carbonData - the value EMISSION_FACTORS's value.
 * @param {string} propertyRegion
 * @returns {?object} - emission factor for a given region.
 */
export const emissionFactorForRegion = (carbonData, propertyRegion) => {
  const { regions: factorsForRegion } = carbonData;
  // if the state value is not present, default to countrycode
  // if both are not present, default to AU
  if (propertyRegion) {
    if (factorsForRegion[propertyRegion]) {
      return { region: propertyRegion, value: factorsForRegion[propertyRegion] };
    }

    const countryCode = propertyRegion.split('-')[0];
    if (countryCode && factorsForRegion[countryCode]) {
      return { region: countryCode, value: factorsForRegion[countryCode] };
    }
  }

  return undefined;
};

/**
 * For a given set of factors (note, normally this is EMISSION_FACTORS from ./constants.js), return
 * the array of valid factors for the period, sorted by start date ascending.
 * @param {object} factors
 * @param {string} propertyRegion
 * @param {DateTime} start
 * @param {DateTime} finish
 * @returns {object} - emission factors.
 * For more information: https://enosi.atlassian.net/wiki/spaces/PT/pages/1885732935/Carbon+emissions+in+CSV+JSON+data+downloads
 */
export const emissionFactors = (factors, propertyRegion, start, finish) => {
  const validFactors = Object.values(factors).filter((factor) => {
    const emissionFactor = emissionFactorForRegion(factor, propertyRegion);
    if (!(emissionFactor)) { return false; }
    const { timeRange } = factor;
    const { start: factorStart, finish: factorFinish } = timeRange;
    return !((factorFinish && factorFinish <= start) || (factorStart && finish < factorStart));
  });

  return validFactors.sort((a, b) => a.timeRange.start - b.timeRange.start);
};

/**
 * Calculate the carbon emissions in kg-CO2•e, given the energy, timestamp and property region.
 * @param {Big} energy
 * @param {DateTime} timestamp
 * @param {string} propertyRegion
 * @returns {Big} - carbon emission in kg-CO2•e.
 */
export const calculateCarbonEmission = (energy, timestamp, propertyRegion) => {
  const validEmissionFactors = emissionFactors(
    EMISSION_FACTORS,
    propertyRegion,
    timestamp,
    timestamp,
  );

  // If we don't detect any valid valid emission factors for the timestamp, we return zero.
  if (validEmissionFactors.length === 0) {
    return Big(DEFAULT_CARBON_FREE_EMISSION_FACTOR);
  }

  // If no values in carbonDatasets, use the default, which is the most recent.
  const latestEmissionFactor = validEmissionFactors[validEmissionFactors.length - 1];
  const latestEmissionFactorRegion = emissionFactorForRegion(
    latestEmissionFactor,
    propertyRegion,
  );
  if (!(latestEmissionFactorRegion)) {
    return Big(DEFAULT_CARBON_FREE_EMISSION_FACTOR).times(energy);
  }
  const { value } = latestEmissionFactorRegion;
  // Calculate! Emissions factors are in g/Wh === kg/kWh. Target outcome is in kg.
  return Big(value).times(Big(energy)).div(Big(1000.0));
};
