import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Big from 'big.js';

import { INHIBIT_CARBON_DATA_VIEWS, PLATFORM_MODE_REBATE } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import { APIConfig } from 'src/config';

import { formatCarbonEmission } from './ChartHelpers';
import { UNIT_CARBON_TOOLTIP_FORMAT } from './chartConstants';

const Div = styled.div`
  border-${(props) => props.align}:
    .25rem solid ${(props) => (props.category === 'imports' ? '#e6c644' : '#3bb667')};
    margin-${(props) => props.align}: .5rem;
    padding-${(props) => props.align}: 1rem;
    text-align: ${(props) => props.align};
`;

const Title = styled.h3.attrs({
  className: 'text-uppercase',
})`
  font-size: .8rem;
  color: #adb5bd;
`;

const getCarbonData = (carbonValue) => {
  const finalValues = formatCarbonEmission(carbonValue);
  const { value: finalValue, label } = finalValues;
  return `${format(UNIT_CARBON_TOOLTIP_FORMAT)(finalValue)}${label}`;
};

const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartSummary component
 */
function ChartSummary(props) {
  const {
    category, tradedValue, tradedEnergy, untradedEnergy, rebatedEnergy, align, mode, carbonData,
  } = props;
  const finalTradedEnergy = (format('.4s')(tradedEnergy === undefined ? 0 : tradedEnergy));
  const finalTradeValue = format('.2f')(tradedValue === undefined ? 0 : tradedValue);

  const title = category === 'imports' ? <FormattedMessage id="common.entities.energy_imports.label" defaultMessage="Energy Imports" />
    : <FormattedMessage id="common.entities.energy_exports.label" defaultMessage="Energy Exports" />;

  if (mode === PLATFORM_MODE_REBATE) {
    const finalRebatedEnergy = format('.4s')(rebatedEnergy === undefined ? 0 : rebatedEnergy);
    return (
      <Div category={category} align={align}>
        <Title>{title}</Title>
        <dl>
          <dt>
            <FormattedMessage id="chart.chart_summary.traded_energy.label" defaultMessage="Traded Energy" />
          </dt>
          <dd>
            {i18nDecimalFormat(finalTradedEnergy)}
            <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
          </dd>
          <dt>
            {' '}
            <FormattedMessage id="chart.chart_summary.discount.label" defaultMessage="Discounted energy" />
          </dt>
          <dd>
            {i18nDecimalFormat(finalRebatedEnergy)}
            <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
          </dd>
          <dt>
            {' '}
            <FormattedMessage id="chart.chart_summary.discount_amount.label" defaultMessage="Discount amount" />
          </dt>
          <dd>
            <FormattedMessage id="common.currency.symbol" values={{ n: finalTradeValue }} />
          </dd>
          {untradedEnergy > 0 && (
            <>
              <dt>
                {' '}
                <FormattedMessage id="chartsummary_untraded_label" defaultMessage="Untraded energy" />
              </dt>
              <dd>
                {i18nDecimalFormat(format('.4s')(untradedEnergy))}
                <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
              </dd>
            </>
          )}
        </dl>
      </Div>
    );
  }

  let averagePrice = 0;
  if (tradedValue !== undefined && tradedEnergy !== undefined && tradedEnergy !== 0) {
    averagePrice = tradedValue / tradedEnergy;
  }

  return (
    <Div category={category} align={align} className={`summary-${category}`}>
      <Title>{title}</Title>
      <dl>
        <dt><FormattedMessage id="chart.chart_summary.average_price.label" defaultMessage="Average Price" /></dt>
        <dd>
          {i18nDecimalFormat(format('.3f')(convertEnergyPrice(averagePrice)))}
          <FormattedMessage id="common.units.energy_cost.abbr.label" defaultMessage="c/kWh" />
        </dd>
        <dt><FormattedMessage id="chartsummary_tradevalue_label" defaultMessage="Traded value" /></dt>
        <dd>
          <FormattedMessage id="common.currency.symbol" values={{ n: finalTradeValue }} />
        </dd>
        <dt><FormattedMessage id="chart.chart_summary.traded_energy.label" defaultMessage="Traded Energy" /></dt>
        <dd>
          {i18nDecimalFormat(finalTradedEnergy)}
          <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
        </dd>
        {untradedEnergy > 0 && (
          <div className="summary-untraded-energy">
            <dt><FormattedMessage id="chartsummary_untraded_label" defaultMessage="Untraded energy" /></dt>
            <dd>
              {i18nDecimalFormat(format('.4s')(untradedEnergy))}
              <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
            </dd>
          </div>
        )}
        {!inhibitCarbonDataViews && carbonData > 0 && (
          <>
            <dt><FormattedMessage id="chart.chart_summary.carbon.label" defaultMessage="Carbon" /></dt>
            <dd>
              {i18nDecimalFormat(getCarbonData(carbonData))}
            </dd>
          </>
        )}
      </dl>
    </Div>
  );
}

ChartSummary.propTypes = {
  category: PropTypes.oneOf(['imports', 'exports']).isRequired,
  tradedValue: PropTypes.number.isRequired,
  tradedEnergy: PropTypes.number.isRequired,
  untradedEnergy: PropTypes.number.isRequired,
  rebatedEnergy: PropTypes.number,
  align: PropTypes.oneOf(['left', 'right']),
  mode: PropTypes.string.isRequired,
  carbonData: PropTypes.oneOfType([
    PropTypes.instanceOf(Big),
    PropTypes.number,
  ]),
};

ChartSummary.defaultProps = {
  align: 'left',
  rebatedEnergy: 0,
  carbonData: 0,
};

export default ChartSummary;
