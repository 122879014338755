import Big from 'big.js';
import { DateTime } from 'luxon';

import { DEFAULT_CARBON_FREE_EMISSION_FACTOR } from './constants';
import { calculateCarbonEmission, useDefaultCarbonFreeEmissions } from './helpers';

/**
 * Calculate carbon data, given the calculation properties of type, timestamp, energy,
 * direction, types and property region.
 * TODO: change to calculations based on a timerange provided.
 * @param {object} calculationProps
 * @param {?string} calculationProps.dataType
 * @param {DateTime} calculationProps.timestamp
 * @param {Big} calculationProps.energy
 * @param {string} calculationProps.direction
 * @param {Array<string>} calculationProps.types
 * @param {string} calculationProps.propertyRegion
 * @returns {Big} - carbon emission.
 */
const calculateCarbonData = (calculationProps) => {
  const {
    dataType, timestamp, energy, direction, types = [], propertyRegion,
  } = calculationProps;

  // For meter data exports and all trade datas except residual imports, carbon emission is 0
  if (useDefaultCarbonFreeEmissions(dataType, direction, types)) {
    return energy.times(DEFAULT_CARBON_FREE_EMISSION_FACTOR);
  }

  return calculateCarbonEmission(energy, timestamp, propertyRegion);
};

export default calculateCarbonData;
