import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TradeSummary from 'src/enosikit/components/TradeSummary';
import Loading from 'src/components/Loading';
import normaliseTradeDataForTradeSummary from './data/normaliseTradeDataForTradeSummary';

class PropertyShowTradeSummary extends React.Component {
  static renderSummaryCard(type, data, summaryClassName) {
    if (!data) {
      return null;
    }
    return <TradeSummary category={type} data={data} summaryClassName={summaryClassName} />;
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property } = this.props;
    const { meters } = property;
    const meterNodes = meters.edges.map((edge) => edge.node);
    const summaryData = normaliseTradeDataForTradeSummary(meterNodes);

    return (
      <div className="trade-summaries mb-4 row row-cols-1 row-cols-sm-2">
        {PropertyShowTradeSummary.renderSummaryCard('imports', summaryData.buy)}
        {PropertyShowTradeSummary.renderSummaryCard('exports', summaryData.sell)}
      </div>
    );
  }
}

PropertyShowTradeSummary.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default PropertyShowTradeSummary;
