import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faBriefcase,
  faCaretDown,
  faCheck,
  faCog,
  faHome,
  faSignOutAlt,
  faTable,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
} from 'reactstrap';
import styled from 'styled-components';

import { AvatarItem } from 'src/enosikit/components/Avatar';
import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import NavigationLogo from 'src/components/Navigation/NavigationLogo';
import { USER_TYPE_CUSTOMER } from 'src/util/constants';
import { APIConfig } from 'src/config';

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

const LeftNav = styled(Nav).attrs({
  className: 'me-auto',
})``;

class CustomerPropertyNavigation extends React.Component {
  static proposalsCount(ruleConn) {
    if (!ruleConn) { return '0'; }
    const { edges } = ruleConn;
    if (!edges) { return '0'; }
    const count = edges.length;
    if (count > 9) {
      return '9+';
    }
    return `${count}`;
  }

  constructor(props) {
    super(props);

    this.state = {
      navbarOpen: false,
      ProfileDropdownOpen: false,
      PropertyDropdownOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({ navbarOpen: !prevState.navbarOpen }));
  };

  renderPropertyNavigation = (propertyConnection, selected) => {
    const { PropertyDropdownOpen } = this.state;
    const properties = [];
    if (propertyConnection.edges && propertyConnection.edges.length > 0) {
      propertyConnection.edges.forEach((edge) => properties.push(edge.node));
    }

    return (
      <>
        <Dropdown
          nav
          inNavbar
          isOpen={PropertyDropdownOpen}
          toggle={this.toggleProperty}
        >
          <Link
            to={`/properties/${selected.id}`}
            className="nav-link"
            style={{ display: 'inline-block', paddingRight: 0 }}
          >
            <FontAwesomeIcon icon={faHome} size="1x" className="me-2" />
            {selected.title}
          </Link>
          <DropdownToggle
            nav
            className="dropdown-toggle-split"
            style={{ display: 'inline-block', paddingLeft: 0 }}
          >
            <FontAwesomeIcon
              icon={faCaretDown}
              size="1x"
              className="ms-2"
            />
          </DropdownToggle>
          <DropdownMenu>
            {properties.map((property) => (
              <Link
                to={`/properties/${property.id}`}
                className={`dropdown-item ${property.id === selected.id ? 'active' : ''}`}
                role="menuitem"
                key={property.id}
              >
                {property.title}
                {property.id === selected.id ? (
                  <>
                    {' '}
                    <FontAwesomeIcon icon={faCheck} size="1x" className="me-2" />
                  </>
                ) : null}
              </Link>
            ))}
          </DropdownMenu>
        </Dropdown>
        <NavItem tabIndex={0} className="nav-trade-rules">
          <Link to={`/properties/${selected.id}/trade-rules/active`} className="nav-link ">
            <FontAwesomeIcon icon={faBriefcase} size="1x" className="me-2" />
            <FormattedMessage id="navigation.customer_property_navigation.trade_rules.link.label" defaultMessage="Trade Rules" />
          </Link>
        </NavItem>
        <NavItem>
          <Link to={`/properties/${selected.id}/trades/history`} className="nav-link">
            <FontAwesomeIcon icon={faTable} size="1x" className="me-2" />
            <FormattedMessage id="navigation.customer_property_navigation.trade_history.link.label" defaultMessage="Trade History" />
          </Link>
        </NavItem>
        <NavItem>
          <Link to={`/properties/${selected.id}/settings`} className="nav-link">
            <FontAwesomeIcon icon={faCog} size="1x" className="me-2" />
            <FormattedMessage id="navigation.customer_property_navigation.property_settings.link.label" defaultMessage="Settings" />
          </Link>
        </NavItem>
      </>
    );
  };

  toggleProfile = () => {
    this.setState((prevState) => ({
      ProfileDropdownOpen: !prevState.ProfileDropdownOpen,
    }));
  };

  toggleProperty = () => {
    this.setState((prevState) => ({
      PropertyDropdownOpen: !prevState.PropertyDropdownOpen,
    }));
  };

  render() {
    const { viewer, properties, property } = this.props;
    const { navbarOpen, ProfileDropdownOpen } = this.state;

    const { community } = property;

    const { proposals, viewerUser } = viewer;
    const {
      email, givenName, familyName, type,
    } = viewerUser;
    let name = [givenName, familyName].filter(Boolean).join(' ');
    if (name === '') {
      name = email;
    }

    if (!viewer) {
      return <AnonymousNavigation />;
    }

    const cobrand = APIConfig().cobrand(community.id, community.title);
    const primaryBrand = APIConfig().primaryBrand();

    return (
      <Navbar light expand="lg">
        <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
          <Link to="/" className="navbar-brand">
            <NavigationLogo cobrand={cobrand} primaryBrand={primaryBrand} />
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={navbarOpen} navbar>
            <LeftNav navbar>{this.renderPropertyNavigation(properties, property)}</LeftNav>
            <RightNav navbar>
              {type === USER_TYPE_CUSTOMER && (
                <NavItem>
                  <Link to="/trade-rules" className="nav-link">
                    <span className="fa-layers fa-fw" style={{ fontSize: '1.5rem', marginTop: '0.6rem' }}>
                      <FontAwesomeIcon icon={faBell} />
                      <span className="fa-layers-counter danger fw-bold" style={{ fontSize: '2.5rem', paddingTop: '.8rem' }}>{CustomerPropertyNavigation.proposalsCount(proposals)}</span>
                    </span>
                  </Link>
                </NavItem>
              )}
              <Dropdown
                nav
                inNavbar
                isOpen={ProfileDropdownOpen}
                toggle={this.toggleProfile}
              >
                <DropdownToggle nav>
                  <AvatarItem
                    primaryText={name}
                    secondaryText={email}
                    avatarImage={
                      <FontAwesomeIcon icon={faUserCircle} size="3x" />
                    }
                    className="d-inline-flex"
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size="1x"
                    className="ms-2"
                  />
                </DropdownToggle>
                <DropdownMenu end>
                  <Link
                    to="/profile/settings"
                    activeClassName="active"
                    className="dropdown-item"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    <FormattedMessage id="navigation.customer_property_navigation.current_user_settings.link.label" defaultMessage="My settings" />
                  </Link>
                  <DropdownItem divider />
                  <Link
                    to="/logout"
                    activeClassName="active"
                    className="dropdown-item"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                    <FormattedMessage id="navigation.customer_property_navigation.log_out.link.label" defaultMessage="Log out" />
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </RightNav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

CustomerPropertyNavigation.propTypes = {
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    community: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  properties: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      }),
    ),
  }).isRequired,
};

CustomerPropertyNavigation.defaultProps = {
  viewer: null,
};

export default withRouter(
  createFragmentContainer(
    CustomerPropertyNavigation,
    {
      viewer: graphql`
        fragment CustomerPropertyNavigation_viewer on Viewer {
          id
          viewerUser {
            email
            givenName
            familyName
            type
          }
          proposals: rules(first:10, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {
            edges {
              node {
                id
              }
            }
          }
        }
      `,
      property: graphql`
        fragment CustomerPropertyNavigation_property on Property {
          id
          title
          community {
            id
            title
          }
        }
      `,
      properties: graphql`
        fragment CustomerPropertyNavigation_properties on PropertyConnection {
          edges {
            node {
              id
              title
            }
          }
        }
      `,
    },
  ),
);
