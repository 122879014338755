import { DateTime } from 'luxon';

/**
 * Takes in a timestamp and returns the formatted i18n date string
 * @param {object} intl - i18n intl object
 * @param {number} timestamp
 * @returns {string} - date string
 */
//  Note: This assumes the browser local timezone not the property timezone
export const getStringAndNumericFormattedDate = (intl, timestamp) => {
  if (!intl || !timestamp) {
    return null;
  }

  return intl.formatDate(DateTime.fromSeconds(timestamp), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

/**
 * Takes in a date object and returns the locale based date
 * and time string in the provided timezone.
 * @param {string} locale - date object
 * @param {string} tz - time zone of the property
 * @param {object} timestamp - date object
 * @returns {string} - date and time formatted string
 */
export const getDateAndTimeFormattedString = (locale, tz, timestamp) => {
  if (!timestamp || !locale || !tz) {
    return null;
  }
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
    timeStyle: 'long',
    timeZone: tz,
  }).format(timestamp);
};
