import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Chart from 'src/enosikit/components/Chart';
import { APIConfig } from 'src/config';
import Loading from 'src/components/Loading';
import {
  SOURCE, SOURCE_HISTORIAN, SOURCE_TRADES, DATA_METER_AGGREGATE,
} from 'src/util/constants';
import { chartDataHandler } from './data/propertyDataManager';
import PropertyShowChartCards from './PropertyShowChartCards';

class PropertyShowChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    this.state = {
      hoverKeys: [],
      selectedKeys: [],
      tooltipTimestamp: null,
      chartSize: { height: 0, width: 0 },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setChartBounds);
    this.setChartBounds();
  }

  componentDidUpdate(prevProps) {
    const { selectedKeys } = this.state;
    const { source } = this.props;
    const { source: prevSource } = prevProps;
    if (source !== prevSource && selectedKeys.length > 0) {
      this.setState({ selectedKeys: [] });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setChartBounds);
  }

  setChartBounds = () => {
    this.setState(() => {
      // Note this excludes padding so ...
      let { clientHeight: height, clientWidth: width } = this.chartRef.current;
      // ...need to get the computed padding.
      const {
        paddingTop, paddingBottom, paddingLeft, paddingRight,
      } = window.getComputedStyle(this.chartRef.current);

      if (paddingTop !== '') { height -= parseFloat(paddingTop); }
      if (paddingBottom !== '') { height -= parseFloat(paddingBottom); }
      if (paddingLeft !== '') { width -= parseFloat(paddingLeft); }
      if (paddingRight !== '') { width -= parseFloat(paddingRight); }

      return { chartSize: { height, width } };
    });
  };

  cardsHoverFunc = (key, hover) => {
    this.setState((prevState) => {
      const newHoverKeys = prevState.hoverKeys;
      if (hover) {
        if (!newHoverKeys.includes(key)) {
          newHoverKeys.push(key);
        }
      } else if (newHoverKeys.includes(key)) {
        newHoverKeys.splice(newHoverKeys.indexOf(key), 1);
      }
      return { hoverKeys: newHoverKeys };
    });
  };

  cardsSelectedFunc = (key) => {
    this.setState((prevState) => {
      const newSelectKeys = prevState.selectedKeys;
      if (newSelectKeys.includes(key)) {
        newSelectKeys.splice(newSelectKeys.indexOf(key), 1);
      } else {
        newSelectKeys.push(key);
      }
      return { selectedKeys: newSelectKeys, hoverKeys: [] };
    });
  };

  controlOptionFunc = () => {
    const { source } = this.props;

    const typeOpts = {
      group: SOURCE,
      items: [{
        value: SOURCE_TRADES, label: <FormattedMessage id="property.property_show.chart.control.source.trades.title" defaultMessage="Trades" />, active: (source === SOURCE_TRADES), disabled: false,
      }, {
        value: SOURCE_HISTORIAN, label: <FormattedMessage id="property.property_show.chart.control.source.historian.title" defaultMessage="Meter" />, active: (source === SOURCE_HISTORIAN), disabled: false,
      }],
    };
    return [typeOpts];
  };

  tooltipUpdate = (timestamp) => {
    this.setState({ tooltipTimestamp: timestamp });
  };

  handleChartClick = () => {
    const { historianAggregation, handleTimespanUpdate } = this.props;
    const { tooltipTimestamp } = this.state;

    if (historianAggregation.match(/^\d+S$/)) {
      return;
    }
    if (tooltipTimestamp === null) {
      return;
    }

    handleTimespanUpdate(tooltipTimestamp, tooltipTimestamp);
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      historianAggregation, property, tradeAggregation,
      source, unit, controlSetStateFunc, chartView,
    } = this.props;
    const {
      tooltipTimestamp, hoverKeys, selectedKeys, chartSize,
    } = this.state;

    const { width } = chartSize;
    const { meters, publicHolidayRegion: propertyRegion } = property;
    const isAggregated = chartView === DATA_METER_AGGREGATE;

    if (!meters) {
      return <Loading />;
    }

    const {
      chartMeterData, totalCarbonEmission,
      tradeCardsData, chartTradeData, multipleMeter,
      meterCardsData, chartSummaryData,
    } = chartDataHandler(meters, tradeAggregation, propertyRegion, isAggregated, source);

    const {
      ChartControls, ChartSummary, ChartMeter, ChartTrade,
    } = Chart;

    return (
      <>
        <div className="mt-4 mb-4 card">
          <div className="card-body" ref={this.chartRef}>
            <div className="d-flex justify-content-between">
              <div className="flex-fill justify-content-center order-2 order-xs-3 col-xs-12">
                <ChartControls
                  buttons={this.controlOptionFunc()}
                  onButtonClick={(opts) => { controlSetStateFunc(opts); }}
                />
              </div>
              <div className="flex-fill order-1 order-xs-1 col-xs-12">
                <ChartSummary
                  align="left"
                  category="imports"
                  tradedEnergy={chartSummaryData.buy.tradedEnergy}
                  tradedValue={chartSummaryData.buy.tradedValue}
                  untradedEnergy={chartSummaryData.buy.untradedEnergy}
                  rebatedEnergy={chartSummaryData.buy.rebatedEnergy}
                  carbonData={totalCarbonEmission}
                  mode={APIConfig().MODE}
                />
              </div>
              <div className="flex-fill order-3 order-xs-2 col-xs-12">
                <ChartSummary
                  align="right"
                  category="exports"
                  tradedEnergy={chartSummaryData.sell.tradedEnergy}
                  tradedValue={chartSummaryData.sell.tradedValue}
                  untradedEnergy={chartSummaryData.sell.untradedEnergy}
                  rebatedEnergy={chartSummaryData.sell.rebatedEnergy}
                  mode={APIConfig().MODE}
                />
              </div>
            </div>
            {source === SOURCE_HISTORIAN && (
              <ChartMeter
                aggregation={historianAggregation}
                data={chartMeterData}
                tooltipTimestamp={tooltipTimestamp}
                tooltipUpdate={(timestamp) => this.tooltipUpdate(timestamp)}
                handleChartClick={() => this.handleChartClick()}
                unit={unit}
                width={width}
                hoverKeys={hoverKeys}
                selectedKeys={selectedKeys}
                isAggregated={isAggregated}
              />
            )}
            {source === SOURCE_TRADES && (
              <ChartTrade
                aggregation={tradeAggregation}
                hoverKeys={hoverKeys}
                selectedKeys={selectedKeys}
                tooltipTimestamp={tooltipTimestamp}
                tooltipUpdate={(timestamp) => this.tooltipUpdate(timestamp)}
                tradeDataBuy={chartTradeData.buy}
                tradeDataSell={chartTradeData.sell}
                unit={unit}
                handleChartClick={() => this.handleChartClick()}
                width={width}
                isAggregated={isAggregated}
                multipleMeter={multipleMeter}
              />
            )}
          </div>
        </div>
        {(source === SOURCE_HISTORIAN && !isAggregated) && (
          <PropertyShowChartCards
            counterParties={meterCardsData}
            hoverFunc={this.cardsHoverFunc}
            hoverKeys={hoverKeys}
            selectedFunc={this.cardsSelectedFunc}
            selectedKeys={selectedKeys}
            meterCard
          />
        )}
        {source === SOURCE_TRADES && (
          <PropertyShowChartCards
            counterParties={tradeCardsData}
            hoverFunc={this.cardsHoverFunc}
            hoverKeys={hoverKeys}
            selectedFunc={this.cardsSelectedFunc}
            selectedKeys={selectedKeys}

          />
        )}
      </>
    );
  }
}

PropertyShowChart.propTypes = {
  chartView: PropTypes.string.isRequired,
  historianAggregation: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  tradeAggregation: PropTypes.string.isRequired,
  handleTimespanUpdate: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
};

export default PropertyShowChart;
